import { Document, Page, StyleSheet, View, Text } from "@react-pdf/renderer";
import React from "react";

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#ffffff",
    padding: 10,
  },
  header: {
    fontSize: 14,
    marginBottom: 5,
    textAlign: "center",
    fontWeight: "bold",
  },
  table: {
    display: "table",
    width: "100%", // Set the width to 100% to use the available space
    marginTop: 5,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000",
  },
  tableRow: {
    display: "flex", // Use flexbox to align items
    flexDirection: "row",
  },
  tableCol: {
    width: "14.28%", // Assign equal width for 7 columns (100% / 7)
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000",
    padding: 5,
    textAlign: "center",
    fontSize: 10,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  tableHeader: {
    backgroundColor: "#f0f0f0",
    fontWeight: "bold",
    fontSize: 10,
  },
  tableCell: {
    fontSize: 10,
  },
});

const FeePaidPdf = ({ data, filters }) => {
  if (!data || data.length === 0) return <Text>No Data Available</Text>;

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Text style={styles.header}>Fee Paid Report</Text>
        <View style={[styles.tableRow]}>
          <Text style={styles.tableCol}>
            Class: {filters.selectedStandard || "All"}
          </Text>
          <Text style={styles.tableCol}>
            Receiver: {filters.selectedCollection || "All"}
          </Text>

          <Text style={styles.tableCol}>
            Year: {filters.selectedYear || "All"}
          </Text>
          <Text style={styles.tableCol}>
            Month: {filters.selectedMonth || "All"}
          </Text>
        </View>
        {/* Table Header */}
        <View style={styles.table}>
          <View style={[styles.tableRow, styles.tableHeader]}>
            <Text style={styles.tableCol}>S.No</Text>
            <Text style={styles.tableCol}>No</Text>
            <Text style={styles.tableCol}>Name</Text>
            <Text style={styles.tableCol}>Class</Text>
            <Text style={styles.tableCol}>Sec</Text>
            <Text style={styles.tableCol}>Total</Text>
            <Text style={styles.tableCol}>Collection</Text>
          </View>

          {/* Table Data */}
          {data.map((detail, index) => (
            <View style={styles.tableRow} key={detail.idf}>
              <Text style={styles.tableCol}>{index + 1}</Text> {/* Serial No */}
              <Text style={styles.tableCol}>{detail.fee_adm_no}</Text>
              <Text style={styles.tableCol}>{detail.name}</Text>
              <Text style={styles.tableCol}>{detail.FeeStandard}</Text>
              <Text style={styles.tableCol}>{detail.sec}</Text>
              <Text style={styles.tableCol}>Rs. {detail.total_fee}/-</Text>
              <Text style={styles.tableCol}>Rs. {detail.collection}/-</Text>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
};

export default FeePaidPdf;
