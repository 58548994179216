import React, { useContext, useState } from 'react';
import { FaBars, FaUserGraduate, FaWallet, FaChalkboard, 
         FaClock, FaUserTie, FaClipboardList, FaPenSquare, 
         FaLock, FaTasks, FaSignOutAlt, FaPlus, FaSearch, FaCalendarAlt , 
         FaBullhorn, FaChartLine,
         FaHome} from "react-icons/fa"; // Import the dashboard icon
import { NavLink, useNavigate } from 'react-router-dom';
import '../CSS/sidebar.css';
import userContext from '../context/UserContext'; 

const Sidebar = ({ toggle, isOpen }) => {
    const { userType, user, logout } = useContext(userContext);
    const navigate = useNavigate();
    const [expandedMenu, setExpandedMenu] = useState(null);

    const menuItem = [
        // Adding the Dashboard item at the beginning
        
        ...(userType.usertype === 'Admin' ? [
            { path: "/dashboard/main", name: "Dashboard", icon: <FaHome /> },
            { path: "/dashboard/studentlist", name: "Students", icon: <FaUserGraduate />, subItems: [
                { path: "/dashboard/attendance", name: "Attendance", icon: <FaClock /> },
            ]},
            { path: "/dashboard/feedetail", name: "Fee", icon: <FaWallet />, subItems: [
                { path: "/dashboard/feeSearchcollect", name: "Collection", icon: <FaSearch /> },
            ] },
            { path: "/dashboard/addquestion", name: "Q Bank", icon:  <FaChartLine />, },
            
                      { path: "/dashboard/registration", name: "Add User", icon: <FaPlus /> },
            { path: "/dashboard/stafflist", name: "Staff", icon: <FaUserTie /> },
          
            { path: "/dashboard/standards", name: "Standards", icon: <FaChalkboard /> },
          
            { path: "/dashboard/announcementslist", name: "Announcements", icon: <FaBullhorn /> },
        ] : []),
        { path: "/dashboard/resultprep", name: "Exams", icon: <FaPenSquare />, subItems: [
            { path: "/dashboard/datesheetprint", name: "DateSheet", icon: <FaCalendarAlt  /> },
            { path: "/dashboard/result", name: "Result", icon: <FaClipboardList /> },
        ]},
        { path: "/dashboard/assigntasks", name: "Assignments", icon: <FaTasks /> },
        { path: "/dashboard/user", name: "Password", icon: <FaLock /> },
    ];

    const handleLogout = () => {
        logout(); 
        navigate("/login");
    };

    const handleMenuClick = (name) => {
        setExpandedMenu(expandedMenu === name ? null : name);
    };

    return (
        <div>
            <div className={`sidebar ${isOpen ? "open" : "closed"}`}>
                <div className="top_section">
                    {isOpen && <h6>{user.username} ( {userType.usertype} )</h6>}
                    <div className="bars" onClick={toggle}>
                        <FaBars />
                    </div>
                </div>
                {menuItem.map((item, index) => (
                    <div key={index}>
                        <NavLink to={item.path} className="link" activeclassname="active" onClick={() => item.subItems ? handleMenuClick(item.name) : null}>
                            <div className="icon">{item.icon}</div>
                            <div style={{ display: isOpen ? "block" : "none" }} className="link_text">{item.name}</div>
                        </NavLink>
                        {item.subItems && expandedMenu === item.name && isOpen && (
                            <div className="submenu">
                                {item.subItems.map((subItem, subIndex) => (
                                    <NavLink to={subItem.path} key={subIndex} className="link sublink" activeclassname="active">
                                        <div className="icon">{subItem.icon}</div>
                                        <div className="link_text">{subItem.name}</div>
                                    </NavLink>
                                ))}
                            </div>
                        )}
                    </div>
                ))}
                <div className="link" onClick={handleLogout} style={{ cursor: "pointer" }}>
                    <div className="icon"><FaSignOutAlt /></div>
                    <div style={{ display: isOpen ? "block" : "none" }} className="link_text">Logout</div>
                </div>
            </div>
        </div>
    );
};

export default Sidebar;
