import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import userContext from '../context/UserContext';

const EditAnnouncement = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [image, setImage] = useState(null);
  const [existingImage, setExistingImage] = useState('');
  const { id } = useParams();
  const navigate = useNavigate();
  const { token } = useContext(userContext);

  useEffect(() => {
    const fetchAnnouncement = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/info/${id}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const { title, description, image } = response.data;
        setTitle(title);
        setDescription(description);
        setExistingImage(image);
      } catch (err) {
        console.error("Error fetching announcement:", err);
      }
    };
    fetchAnnouncement(); 
  }, [id, token]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    if (image) {
      formData.append('image', image);
    }
    try {
      await axios.patch(`${process.env.REACT_APP_API_URL}/info/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`
        }
      });
      navigate('/dashboard/announcementslist');
    } catch (err) {
      console.error("Error updating announcement:", err);
    }
  };

  return (
    <div className="card col-md-8 mx-auto">
      <div className="card-header">
        <h2 className="text-center">Edit Announcement</h2>
      </div>
      <div className="card-body">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label><b>Title</b></label>
            <input type="text" className="form-control" placeholder="Announcement Title..." value={title} onChange={(e) => setTitle(e.target.value)} required />
          </div>
          <div className="form-group">
            <label><b>Description</b></label>
            <textarea className="form-control" placeholder="Announcement Description..." value={description} onChange={(e) => setDescription(e.target.value)} required />
          </div>
          <div className="form-group">
            <label><b>Image</b></label>
            {existingImage && (
              <img src={`${process.env.REACT_APP_API_URL}/${existingImage}`} alt={title} style={{ width: '100px', height: '100px' }} />
            )}
            <input type="file" className="form-control" onChange={(e) => setImage(e.target.files[0])} />
          </div>
          <button className="btn btn-primary mt-3">Update Announcement</button>
        </form>
      </div>
    </div>
  );
}; 

export default EditAnnouncement;