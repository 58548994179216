import React, { useState, useEffect } from "react";
import image1 from './Images/image1.JPG';
import image2 from './Images/image2.JPG';
import image3 from './Images/image3.JPG';
import Adil from './Images/adil.JPG';
import image6 from './Images/image6.JPG';
import image7 from './Images/image7.JPG';
import image8 from './Images/image8.JPG';
import image9 from './Images/image9.JPG';
import image10 from './Images/image10.JPG';
// import image4 from './Images/image4.JPG';
import './CSS/LogoStyle.css';

const Home = () => {
  const [selectedImage, setSelectedImage] = useState(0);
  const allImages = [image1, image2, image3, image6, image7, image8, image9, image10];

  useEffect(() => {
    // Set an interval to change the selected image every 3 seconds
    const timer = setInterval(() => {
      setSelectedImage((prevImage) => (prevImage + 1) % allImages.length);
    }, 3000);

    // Clear the interval on component unmount
    return () => clearInterval(timer);
  }, [allImages.length]);

  return (
    <div>
      <div
        className="mt-2"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <h2
          style={{
            color: "#331e38",
            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.4)",
            fontFamily: "ArialBlack",
            fontSize: "32px",
            letterSpacing: "1px",
            padding: "10px",
            borderRadius: "10px",
            background: "rgba(255, 255, 255, 0.8)",
            display: "inline-block",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          }}
          className="text-center"
        >
          ISLAMIC SCHOLAR PUBLIC SCHOOL, WAH CANTT
        </h2>
      </div>

      <br />

      {/* Image Slider */}
      <div
        className="carousel slide text-center mt-3 px-4"
        style={{ boxShadow: "5px 5px 5px rgba(0, 0, 0, 0.6)" }}
      >
        <img
          src={allImages[selectedImage]}
          className="d-block w-100 img-shadow"
          alt="Slide"
        />

        <button
          className="btn btn-primary mt-3"
          onClick={() => {
            setSelectedImage((prevImage) => (prevImage - 1 + allImages.length) % allImages.length);
          }}
        >
          Previous
        </button>
        <button
          className="btn btn-primary ml-3 mt-3"
          onClick={() => {
            setSelectedImage((prevImage) => (prevImage + 1) % allImages.length);
          }}
        >
          Next
        </button>
        <br/>
        <br />
      </div>
      <br/>

     

      <div className="container-fluid bg-light overflow-hidden my-5 px-lg-0">
        <div className="container about px-lg-0">
          <div className="row g-0 mx-lg-0">
            <div className="col-lg-6 ps-lg-0 wow fadeIn" style={{ minHeight: "400px" }}>
              <div className="position-relative h-100">
                <img
                  className="position-absolute img-fluid w-100 h-100"
                  src={Adil}
                  style={{ objectFit: "cover" }}
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-6 about-text py-5 wow fadeIn">
              <div className="p-lg-5 pe-lg-0">
                <h3 className="text-primary">About Us</h3>
                <h1 className="mb-4">Adil Shahzad</h1>
                <p>20 years experience in teaching field with administration C.E.O (M.Sc Physics & B.Sc (Double Maths).</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Service Section */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: "600px" }}>
            <h3 className="text-primary">Our Services</h3>
            <h1 className="mb-4">We strive to serve the nation.</h1>
          </div>
          <div className="row g-4">
            {/* Repeat the following structure for each service item */}
            <div className="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.1s">
              <div className="service-item rounded overflow-hidden">
                <img
                  className="img-fluid"
                  src={image6}
                  alt=""
                />
                <div className="position-relative p-4 pt-0">
                  <div className="service-icon">
                    <i className="fa fa-solar-panel fa-3x"></i>
                  </div>
                  <h4 className="mb-3">Solar Electricity</h4>
                  <p>We provide education with loadsheding free environment with the help of solar electricity.</p>
                </div>
              </div>
            </div>
            {/* Add more service items here */}
            <div className="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.3s">
              <div className="service-item rounded overflow-hidden">
                <img
                  className="img-fluid"
                  src={image7}
                  alt=""
                />
                <div className="position-relative p-4 pt-0">
                  <div className="service-icon">
                    <i className="fa fa-book fa-3x"></i>
                  </div>
                  <h4 className="mb-3">Tafseer e Quran</h4>
                  <p>We do provide tafseer e Quran education to students parallel to academic education.</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.5s">
              <div className="service-item rounded overflow-hidden">
                <img
                  className="img-fluid"
                  src={image8}
                  alt=""
                />
                <div className="position-relative p-4 pt-0">
                  <div className="service-icon">
                    <i className="fa fa-flask fa-3x"></i>
                  </div>
                  <h4 className="mb-3">Science & Computer Lab</h4>
                  <p>We do provide quality standard Science & Computer Lab for students to meet their needs.</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.1s">
              <div className="service-item rounded overflow-hidden">
                <img
                  className="img-fluid"
                  src={image9}
                  alt=""
                />
                <div className="position-relative p-4 pt-0">
                  <div className="service-icon">
                    <i className="fa fa-school fa-3x"></i>
                  </div>
                  <h4 className="mb-3">Free Coaching Classes</h4>
                  <p>Free coaching classes are arranged for students.</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.3s">
              <div className="service-item rounded overflow-hidden">
                <img
                  className="img-fluid"
                  src={image10}
                  alt=""
                />
                <div className="position-relative p-4 pt-0">
                  <div className="service-icon">
                    <i className="fa fa-handshake fa-3x"></i>
                  </div>
                  <h4 className="mb-3">Free Education for Orphans</h4>
                  <p>We do provide free education to orphans.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Footer */}
      <div className="footer">
        <footer className="text-center mt-4 py-1" style={{ backgroundColor: 'grey', color: 'white' }}>
          <div className="row" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div className="container md-col-6 px-4" style={{ flex: 1, textAlign: 'left', padding: '10px' }}>
              <p style={{ color: 'white' }}>
                <b>P.I.P.S</b> Contact: <a href="https://techinfolab360.xyz" style={{ color: 'white' }}>03705057482</a>
              </p>
            </div>
            <div className="container md-col-6" style={{ flex: 1, textAlign: 'left', padding: '10px' }}>
              <p style={{ color: 'white' }}>
                <b>Designed by</b> Techinfolab, Website: <a href="https://techinfolab360.xyz" style={{ color: 'white' }}>https://techinfolab360.xyz</a>, E-mail: <a href="mailto:techinfolab360@gmail.com" style={{ color: 'white' }}>techinfolab360@gmail.com</a>
              </p>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default Home;
