import React, { useState } from "react"; import userContext from './UserContext';

const AuthProvider = ({ children }) => { 
    const [userType, setUserType] = useState({ usertype: ''}); 
    const [user, setUser] = useState({ username: ''}); 
    const [token, setToken] = useState('');
console.log(userType);
 
// Logout function (optional)
const logout = () => {
    setUser({ username: '', });
    setToken('');
};

return (
    <userContext.Provider value={{ user, setUser, token, setToken, logout, userType, setUserType }}>
        {children}
    </userContext.Provider>
);
};

export default AuthProvider;