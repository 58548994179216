import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import userContext from "../context/UserContext";

const Report = () => {
  const { token } = useContext(userContext);
  const [reportData, setReportData] = useState([]);

  useEffect(() => {
    const fetchReportData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/feestats/reporting`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        console.log("Fetched report data:", response.data);
        setReportData(response.data.data);
      } catch (error) {
        console.error("Error fetching report data:", error);
      }
    };

    fetchReportData();
  }, [token]);

  // Function to sort report data by year and month in ascending order
  const sortedReportData = reportData.sort((a, b) => {
    // Convert month/year to a comparable value
    const aDate = new Date(a.fyear, a.fmonth - 1); // Month is 0-indexed
    const bDate = new Date(b.fyear, b.fmonth - 1);
    return aDate - bDate; // Sort in ascending order
  });

  return (
    <>
      <h2 className="text-center">COLLECTION REPORT IN VARIOUS HEADS</h2>
      <table className="table table-striped">
        <thead className="thead-dark">
          <tr>
            <th>Ser</th>
            <th>M / Y</th>
            <th>Fee / Get </th>
            <th>Fine / Get</th>
            <th>Adm Fee / Get</th>
            <th>Misc Fee / Get</th>
            <th>Exam Fee / Get</th>
            <th>Total Fee / Get</th>
          </tr>
        </thead>
        <tbody>
          {sortedReportData.length > 0 ? (
            sortedReportData.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>
                  {item.fmonth} / {item.fyear}{" "}
                </td>
                <td>
                  {(item.monthly_fee_feetbl || 0) + (item.adm_arrears || 0)} /{" "}
                  {(item.collection || 0)}
                </td>
                <td>
                  {(item.fine_fee || 0) + (item.fine_arrears || 0)} / {(item.fine_collection || 0)}
                </td>
                <td>
                  {(item.adm_fee || 0) + (item.adm_arrears || 0)} / {(item.adm_collection || 0)}
                </td>
                <td>
                  {(item.misc_fee || 0) + (item.misc_arrears || 0)} / {(item.misc_collection || 0)}
                </td>
                <td>
                  {(item.exam_fee || 0) + (item.exam_arrears || 0)} / {(item.exam_collection || 0)}
                </td>
                <td>
                  {(item.total_fee || 0) + (item.total_arrears || 0)} /{" "}
                  {(item.total_collection || 0)}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="8" className="text-center">
                No data available
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
};

export default Report;
