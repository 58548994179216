import React, { useEffect, useState, useContext, useCallback } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import userContext from '../context/UserContext';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PaperPdf from './PaperPdf';
import { FaTrash } from "react-icons/fa";

const Paper = () => {
    const { standard: paramStandard } = useParams();
    const [standards, setStandards] = useState([]);
    const [standard, setStandard] = useState(paramStandard || '');
    const [subject, setSubject] = useState('');
    const [examYear, setExamYear] = useState('');
    const [exam, setExam] = useState('');
    const [subjects, setSubjects] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [examYears, setExamYears] = useState([]);
    const [exams, setExams] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const { token } = useContext(userContext);

    const fetchStandards = useCallback(async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/classes`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setStandards(response.data);
        } catch (error) {
            console.error('Error fetching standards:', error);
            setErrorMessage('Failed to fetch standards');
        }
    }, [token]);

    const fetchSubjects = useCallback(async () => {
        if (standard) {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/paperserver/subjects`, {
                    params: { standard },
                    headers: { Authorization: `Bearer ${token}` },
                });
                setSubjects(response.data);
            } catch (error) {
                console.error('Error fetching subjects:', error);
                setErrorMessage('Failed to fetch subjects');
            }
        } else {
            setSubjects([]);
        }
    }, [standard, token]);

    const fetchExamYears = useCallback(async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/paperserver/examyear`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setExamYears(response.data);
        } catch (error) {
            console.error('Error fetching exam years:', error);
            setErrorMessage('Failed to fetch exam years');
        }
    }, [token]);

    const fetchExams = useCallback(async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/paperserver/exam`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setExams(response.data);
        } catch (error) {
            console.error('Error fetching exams:', error);
            setErrorMessage('Failed to fetch exams');
        }
    }, [token]);

    const fetchQuestions = useCallback(async () => {
        if (standard && subject && examYear && exam) {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/paperserver/questionpaper`, {
                    params: { standard, subject, examYear, exam },
                    headers: { Authorization: `Bearer ${token}` },
                });
                setQuestions(response.data);
                console.log(response.data);
            } catch (error) {
                console.error('Error fetching questions:', error.response ? error.response.data : error.message);
                setErrorMessage('Failed to fetch questions');
            }
        }
    }, [standard, subject, examYear, exam, token]);

    useEffect(() => {
        fetchStandards();
    }, [fetchStandards]);

    useEffect(() => {
        fetchSubjects();
    }, [standard, fetchSubjects]);

    useEffect(() => {
        fetchExamYears();
    }, [fetchExamYears]);

    useEffect(() => {
        fetchExams();
    }, [fetchExams]);

    useEffect(() => {
        fetchQuestions();
    }, [standard, subject, examYear, exam, fetchQuestions]);

    const objectiveQuestions = questions.filter(q => ['Fill in blanks', 'True / False', 'Multiple Choice'].includes(q.q_type));
    const subjectiveQuestions = questions.filter(q => ['Short', 'Long'].includes(q.q_type));

    const deleteQuestion = async (paperId) => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/paperserver/deleteQuestion/${paperId}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setQuestions(prevQuestions => prevQuestions.filter(q => q.paper_id !== paperId));
        } catch (error) {
            console.error('Error deleting question:', error);
            setErrorMessage('Failed to delete question');
        }
    };

    return (
        <div className="paper-container">
            <h1 className='text-center'>Paper Structure</h1>
            {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
            <hr />
            <div className='row'>
                <div className="col-md-3">
                    <label htmlFor="standard">Standard:</label>
                    <select id="standard" className="form-control" value={standard} onChange={(e) => setStandard(e.target.value)}>
                        <option value="">Select Standard</option>
                        {standards.map(std => (
                            <option key={std.sid} value={std.sid}>{std.standard}</option>
                        ))}
                    </select>
                </div>
                <div className="col-md-3">
                    <label htmlFor="subject">Subject:</label>
                    <select id="subject" className="form-control" value={subject} onChange={(e) => setSubject(e.target.value)}>
                        <option value="">Select Subject</option>
                        {subjects.map(sub => (
                            <option key={sub.subjectId} value={sub.subject}>{sub.subject}</option>
                        ))}
                    </select>
                </div>
                <div className="col-md-2">
                    <label htmlFor="examYear">Exam Year:</label>
                    <select id="examYear" className="form-control" value={examYear} onChange={(e) => setExamYear(e.target.value)}>
                        <option value="">Select Exam Year</option>
                        {examYears.map(year => (
                            <option key={year.examyear} value={year.examyear}>{year.examyear}</option>
                        ))}
                    </select>
                </div>
                <div className="col-md-2">
                    <label htmlFor="exam">Exam:</label>
                    <select id="exam" className="form-control" value={exam} onChange={(e) => setExam(e.target.value)}>
                        <option value="">Select Exam</option>
                        {exams.map(ex => (
                            <option key={ex.exam} value={ex.exam}>{ex.exam}</option>
                        ))}
                    </select>
                </div>
                <div className="col-md-2">
                    {/* PDF download link */}
                    <PDFDownloadLink document={<PaperPdf questions={questions} />} fileName="paper_structure.pdf">
                        {({ loading }) => (loading ? 'Loading document...' : <button className='btn btn-sm btn-primary'>Download PDF</button>)}
                    </PDFDownloadLink>
                </div>
            </div>
            <hr />
            <div className='col-md-8 justify-content-center ml-4'>
                <h5 className='text-center'>OBJECTIVE</h5>
                <p>Marks: {objectiveQuestions.reduce((total, q) => total + (q.q_marks || 0), 0)}</p>

                {/* Fill in blanks Table */}
                {objectiveQuestions.some(q => q.q_type === 'Fill in blanks') && (
                    <div>
                        <h6>1. Fill in blanks: (Marks: {objectiveQuestions.filter(q => q.q_type === 'Fill in blanks').reduce((total, q) => total + (q.q_marks || 0), 0)})</h6>
                        <table className="table table-bordered text-left" style={{ borderColor: "white" }}>
                            <tbody>
                                {objectiveQuestions.filter(q => q.q_type === 'Fill in blanks').map((q, index) => (
                                    <tr key={q.qid}>
                                        <td style={{ width: "5%" }}>{index + 1}</td>
                                        <td style={{ width: "65%" }}>{q.question}</td>
                                        <td style={{ width: "10%" }}>{q.q_marks || 0}</td>
                                        <td style={{ width: "10%" }}>
                                            <button
                                                className="btn btn-danger btn-sm"
                                                onClick={() => deleteQuestion(q.paper_id)}
                                            >
                                                <FaTrash />
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}

                {/* Multiple Choice Table */}
                {objectiveQuestions.some(q => q.q_type === 'Multiple Choice') && (
                    <div>
                        <h6>2. Choose the correct option from the following: (Marks: {objectiveQuestions.filter(q => q.q_type === 'Multiple Choice').reduce((total, q) => total + (q.q_marks || 0), 0)})</h6>
                        <table className="table table-bordered text-left" style={{ borderColor: "white" }}>
                            <tbody>
                                {objectiveQuestions.filter(q => q.q_type === 'Multiple Choice').map((q, index) => (
                                    <React.Fragment key={q.qid}>
                                        <tr>
                                            <td style={{ width: "5%" }}>{index + 1}</td>
                                            <td style={{ width: "65%" }}>{q.question}</td>
                                            <td style={{ width: "10%" }}>{q.q_marks || 0}</td>
                                            <td style={{ width: "10%" }}>
                                                <button
                                                    className="btn btn-danger btn-sm"
                                                    onClick={() => deleteQuestion(q.paper_id)}
                                                >
                                                     <FaTrash />
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="4" >
                                              
                                                <p className='ml-10'>a. <b>{q.opt1}</b>   | b. <b>{q.opt2} </b>   | c. <b>{q.opt3} </b>   | d. <b>{q.opt4}</b></p>
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}

                {/* True / False Table */}
                {objectiveQuestions.some(q => q.q_type === 'True / False') && (
                    <div>
                        <h6>3. Mark Tick for True and X for False: (Marks: {objectiveQuestions.filter(q => q.q_type === 'True / False').reduce((total, q) => total + (q.q_marks || 0), 0)})</h6>
                        <table className="table table-bordered text-left" style={{ borderColor: "white" }}>
                            <tbody>
                                {objectiveQuestions.filter(q => q.q_type === 'True / False').map((q, index) => (
                                    <tr key={q.qid}>
                                        <td style={{ width: "5%" }}>{index + 1}</td>
                                        <td style={{ width: "65%" }}>{q.question}</td>
                                        <td style={{ width: "10%" }}>{q.q_marks || 0}</td>
                                        <td style={{ width: "10%" }}>
                                            <button
                                                className="btn btn-danger btn-sm"
                                                onClick={() => deleteQuestion(q.paper_id)}
                                            >
                                                <FaTrash />
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}

                <h5 className='text-center'>
                    SUBJECTIVE                </h5><p>Marks: {subjectiveQuestions.reduce((total, q) => total + (q.q_marks || 0), 0)}</p>
                {subjectiveQuestions.length > 0 ? (
                    <table className="table table-bordered text-left" style={{ borderColor: "white" }}>
                        <tbody>
                            {subjectiveQuestions.map((q, index) => (
                                <tr key={q.qid}>
                                    <td style={{ width: "5%" }}>{index + 1}</td>
                                    <td style={{ width: "65%" }}>{q.question}</td>
                                    <td style={{ width: "10%" }}>{q.q_marks || 0}</td>
                                    <td style={{ width: "10%" }}>
                                        <button
                                            className="btn btn-danger btn-sm"
                                            onClick={() => deleteQuestion(q.paper_id)}
                                        >
                                            <FaTrash />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <p>No subjective questions found.</p>
                )}
            </div>
        </div>
    );
};

export default Paper;
