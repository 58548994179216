import React, { useEffect, useState, useContext } from 'react';
import { useTable } from 'react-table';
import axios from 'axios';
import userContext from "../context/UserContext";
import { useNavigate } from 'react-router-dom';
import { PDFDownloadLink, Document, Page, StyleSheet, View, Text } from '@react-pdf/renderer';

// Style for the PDF Document
const styles = StyleSheet.create({
  page: {
    backgroundColor: "#ffffff",
    padding: 10,
    flexDirection: 'row', // Optional: if you want to align items in a row
  },
  header: {
    fontSize: 14,
    marginBottom: 5,
    textAlign: "center",
    fontWeight: "bold",
  },
  table: {
    display: "table",
    width: "100%",
    marginTop: 5,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000",
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
  },
  tableCol: {
    width: "14.28%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000",
    padding: 5,
    textAlign: "center",
    fontSize: 10,
  },
  tableHeader: {
    backgroundColor: "#f0f0f0",
    fontWeight: "bold",
    fontSize: 10,
  },
});

// PDF Component
const DateSheetPdf = ({ data, exam, year }) => {
  if (!data || data.length === 0) return <Text>No Data Available</Text>;

  const uniqueStandards = [...new Set(data.map(record => record.standard))];
  const uniqueDates = [...new Set(data.map(record => new Date(record.date).toLocaleDateString('en-CA')))];

  const matrix = uniqueDates.map(date => {
    const row = { date };
    uniqueStandards.forEach(standard => {
      const found = data.find(record => new Date(record.date).toLocaleDateString('en-CA') === date && record.standard === standard);
      row[standard] = found ? found.subject : '';
    });
    return row;
  });

  return (
    <Document>
      <Page size={[842, 595]} style={styles.page}> {/* A4 Landscape */}
        <View style={styles.table}>
        <Text style={styles.header}>Date Sheet - {exam}, {year}</Text>
          <View style={styles.tableRow}>
            <View style={{ ...styles.tableCol, ...styles.tableHeader }}>
              <Text>Date</Text>
            </View>
            {uniqueStandards.map(standard => (
              <View key={standard} style={{ ...styles.tableCol, ...styles.tableHeader }}>
                <Text>{standard}</Text>
              </View>
            ))}
          </View>
          {matrix.map((row, rowIndex) => (
            <View key={rowIndex} style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text>{row.date}</Text>
              </View>
              {uniqueStandards.map(standard => (
                <View key={standard} style={styles.tableCol}>
                  <Text>{row[standard]}</Text>
                </View>
              ))}
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
};



const DateSheetTable = () => {
  const [data, setData] = useState([]);
  const [standards, setStandards] = useState([]);
  const [exams, setExams] = useState([]);
  const [years, setYears] = useState([]);
  const [selectedExam, setSelectedExam] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const { token } = useContext(userContext);
  const navigate = useNavigate();

  // Fetch exams and years
  useEffect(() => {
    const fetchExamsAndYears = async () => {
      try {
        const examsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/datesheet/examination`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const yearsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/datesheet/years`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setExams(examsResponse.data);
        setYears(yearsResponse.data);
      } catch (error) {
        console.error('Error fetching exams and years:', error);
      }
    };
    fetchExamsAndYears();
  }, [token]);

  // Fetch data when exam and year are selected
  useEffect(() => {
    const fetchData = async () => {
      if (selectedExam && selectedYear) {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/datesheet/report?year=${selectedYear}&exam=${selectedExam}`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          const transformedData = response.data.records.map(record => ({
            date: record.date,
            subject: record.subject,
            standard: record.standard,
          }));
          setData(transformedData);
          const uniqueStandards = [...new Set(transformedData.map(item => item.standard))];
          setStandards(uniqueStandards);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
    };
    fetchData();
  }, [selectedExam, selectedYear, token]);

  // Prepare table columns
  const columns = React.useMemo(() => {
    const cols = [{
      Header: 'Date',
      accessor: 'date',
      Cell: ({ value }) => new Date(value).toLocaleDateString('en-CA'),
    }];

    standards.forEach(standard => {
      cols.push({
        Header: standard,
        accessor: record => record && record.standard === standard ? record.subject : '',
      });
    });

    return cols;
  }, [standards]);

  // Use react-table hooks
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data });

  const handledatesheet = ()=> {
    navigate('/dashboard/datesheet');
  }

  return (
    <div>
      <div className="row">
        <div className='col-md-4'>
          <button className='btn btn-success' onClick={handledatesheet}>Prepare Date Sheet</button>

        </div>
        <div className='col-md-4'>
          <select
            id="exam"
            value={selectedExam}
            onChange={e => setSelectedExam(e.target.value)}
            className="form-control mx-2 shadow p-2 rounded-pill border-3 border-info"
          >
            <option value="">--Select Exam--</option>
            {exams.map(exam => (
              <option key={exam.exam} value={exam.exam}>{exam.exam}</option>
            ))}
          </select>
        </div>
        <div className='col-md-4'>
          <select
            id="year"
            value={selectedYear}
            onChange={e => setSelectedYear(e.target.value)}
            className="form-control mx-2 shadow p-2 rounded-pill border-3 border-info"
          >
            <option value="">--Select Year--</option>
            {years.map(year => (
              <option key={year.year} value={year.year}>{year.year}</option>
            ))}
          </select>
        </div>
      </div>
      <h3 className='text-center'>
        {selectedExam && selectedYear ? `DATE SHEET - ${selectedExam}, ${selectedYear}` : 'Please select Exam and Year'}
      </h3>
      <table {...getTableProps()} style={{ border: 'solid 1px black', width: '100%' }}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()} style={{ border: 'solid 1px black', padding: '10px' }}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {data.length === 0 ? (
            <tr>
              <td colSpan={columns.length} style={{ textAlign: 'center' }}>No Results Found</td>
            </tr>
          ) : (rows.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <td {...cell.getCellProps()} style={{ border: 'solid 1px black', padding: '10px' }}>
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          }))}
        </tbody>
      </table>

      {/* PDF Download Link */}
      <div className="text-center mt-4">
        <PDFDownloadLink
          document={<DateSheetPdf data={data} exam={selectedExam} year={selectedYear} />}
          fileName={`Date_Sheet_${selectedExam}_${selectedYear}.pdf`}
        >
          {({ blob, url, loading, error }) =>
            loading ? 'Loading document...' : 'Download Date Sheet PDF'
          }
        </PDFDownloadLink>
      </div>
    </div>
  );
};

export default DateSheetTable;
