import React, { useState } from "react";
import { Link } from 'react-router-dom';
import logo from '../Images/logo.JPG'; // Adjust the relative path if needed

const Navbar = () => {
  const [isActive, setIsActive] = useState(false);
  const [isDropdownActive, setIsDropdownActive] = useState(false);

  return (
    <nav className="bg-[#0b4f6c] py-1 mx-2">
      <div className="flex justify-between text-white px-6 md:px-8 items-center">
        <div className="flex items-center">
          <img src={logo} alt="Logo" className="h-8 w-8 mr-2" /> {/* Logo */}
          <span className="text-2xl font-bold">ISPS</span>
        </div>
        <div className="hidden md:block">
        <div className="flex space-x-4 items-center">
            <Link to="/" className="text-white no-underline hover:bg-[#331e38] py-1.5 px-4 transition">Home</Link>
            <Link to="/AnnouncementPublished" className="text-white no-underline hover:bg-[#331e38] py-1.5 px-4 transition">Announcements</Link>
            <Link to="/invoicefee" className="block text-white no-underline hover:bg-[#331e38] py-1.5 px-4 transition"> Invoices</Link>
           
            <div className="relative inline-block">
              <button 
                className="text-white no-underline hover:bg-[#331e38] py-1.5 px-4 transition flex items-center"
                onClick={() => setIsDropdownActive(!isDropdownActive)}
              >
                Pages <i className="fas fa-caret-down ml-1"></i>
              </button>
              {isDropdownActive && (
                <div className="absolute bg-[#0b4f6c] text-white py-2 px-4 mt-2 rounded">
                  <Link to="/dashboard" className="block text-white no-underline hover:bg-[#331e38] py-1.5 px-4 transition">App</Link>
                  <Link to="/tasksview" className="block text-white no-underline hover:bg-[#331e38] py-1.5 px-4 transition">Diary</Link>
                 
                </div>
              )}
            </div>
          </div>
        </div>
        <span 
          className={`${isActive ? "hidden" : "block"} md:hidden text-white`} 
          onClick={() => setIsActive(true)}
        >|||</span>
        <span 
          className={`${isActive ? "block" : "hidden"} md:hidden text-white`} 
          onClick={() => setIsActive(false)}
        >x</span>
      </div>
      {isActive && (
        <div className="md:hidden flex flex-col space-y-4 text-white mt-4 items-start">
          <Link to="/" className="text-white no-underline hover:bg-[#8f3f2f] py-1.5 px-4 transition">Home</Link>
          <Link to="/AnnouncementPublished" className="text-white no-underline hover:bg-[#8f3f2f] py-1.5 px-4 transition">Announcement</Link>
          <Link to="/invoicefee" className="block text-white no-underline hover:bg-[#331e38] py-1.5 px-4 transition"> Invoices</Link>
          <button 
            className="text-white no-underline hover:bg-[#8f3f2f] py-1.5 px-4 transition flex items-center"
            onClick={() => setIsDropdownActive(!isDropdownActive)}
          >
            Pages <i className="fas fa-caret-down ml-1"></i>
          </button>
          {isDropdownActive && (
            <div className="flex flex-col space-y-4 text-white mt-2">
               <Link to="/dashboard" className="block text-white no-underline hover:bg-[#331e38] py-1.5 px-4 transition">App</Link>
                  <Link to="/tasksview" className="block text-white no-underline hover:bg-[#331e38] py-1.5 px-4 transition">Diary</Link>
                
                </div>

          )}
        </div>
      )}
    </nav>
  );
};

export default Navbar;
