import React, { useState } from "react"; 
import axios from "axios"; 
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./CSS/Login.css"; // Use the same CSS for consistent styling

const Registration = () => { 
    const [username, setUsername] = useState(''); 
    const [password, setPassword] = useState(''); 
    const [usertype, setUsertype] = useState(''); 
    const navigate = useNavigate(); 

    // Form submit function
    const handleSubmit = (e) => { 
        e.preventDefault(); 
        console.log('Submitting form with:', { username, password, usertype });
    
        axios.post(`${process.env.REACT_APP_API_URL}/user/signup`, { username, password, usertype })
            .then(res => {
                console.log('Response:', res.data); 
                navigate('/');
            })
            .catch(err => {
                console.error('Error:', err.response ? err.response.data : err); 
                alert("Registration failed!"); 
            });
    }

    return ( 
        <div className="login-background">
            <div className="container col-md-4 login-box">
                <h1 className="my-4 text-center">Add New User</h1>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>User Name</label>
                        <input 
                            type="text" 
                            className="form-control" 
                            id="usernameInput" 
                            placeholder="User Name" 
                            autoComplete="new-password" 
                            onChange={(e) => setUsername(e.target.value)} 
                            required 
                        />
                    </div>
                    <div className="form-group">
                        <label>Password</label>
                        <input
                            type="password"
                            className="form-control"
                            id="passwordInput"
                            placeholder="Password"
                            onChange={(e) => setPassword(e.target.value)}
                            required 
                            autoComplete="new-password"
                        />
                    </div>
                    <div className="form-group">
                        <label>Usertype</label>
                        <select 
                            className="form-control" 
                            id="usertypeInput" 
                            value={usertype} 
                            onChange={(e) => setUsertype(e.target.value)} 
                            required
                        >
                            <option value="">Select Usertype</option>
                            <option value="Admin">Admin</option>
                            <option value="User">User</option>
                            <option value="Teacher">Teacher</option>
                        </select>
                    </div>             
                    <button type="submit" className="btn btn-primary mt-2">
                        Submit
                    </button>
                </form>
                <div className="contact-info">
                    <p>Contact us: <a href="mailto:techinfolab360@gmail.com" style={{color: 'lightblue'}}>techinfolab360@gmail.com</a></p>
                    <p>Website: <a href="https://techinfolab360.xyz" style={{color: 'lightblue'}}>https://techinfolab360.xyz</a></p>
                </div>
            </div>
        </div>
    );
}

export default Registration;
