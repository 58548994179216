import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import userContext from "../context/UserContext";
import { useNavigate } from "react-router-dom";

export default function FeeCollection() {
  const { user } = useContext(userContext);
  const { idf } = useParams();
  const { token } = useContext(userContext);
  const navigate = useNavigate();

  const [feedetail, setFeeDetail] = useState({
    fee_adm_no: "",
    idf: "",
    feestandard: "",
    monthly_fee: "",
    collection: "",
    fine_collection: "",
    adm_collection: "",
    misc_collection: "",
    exam_collection: "",
    total_fee: "",
    fine_fee: "",
    exam_fee: "",
    misc_fee: "",
    adm_fee: "",
    arrears: "",
    fine_arrears: "",
    adm_arrears: "",
    exam_arrears: "",
    misc_arrears: "",
    total_arrears: "",
    total_balance: "",
  });

  useEffect(() => {
  const fetchInvoices = async () => {
    try {
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth() + 1; // getMonth() returns 0-based index

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/fee/${idf}?fyear=${currentYear}&fmonth=${currentMonth}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success && response.data.data.length > 0) {
        const data = response.data.data[0];
        
        // Calculate initial values for collection fields
        const initialCollectionValues = {
          collection: (parseFloat(data.monthly_fee_feetbl) || 0) + (parseFloat(data.arrears) || 0),
          fine_collection: (parseFloat(data.fine_fee) || 0) + (parseFloat(data.fine_arrears) || 0),
          adm_collection: (parseFloat(data.adm_fee) || 0) + (parseFloat(data.adm_arrears) || 0),
          misc_collection: (parseFloat(data.misc_fee) || 0) + (parseFloat(data.misc_arrears) || 0),
          exam_collection: (parseFloat(data.exam_fee) || 0) + (parseFloat(data.exam_arrears) || 0),
        };
        
        
        setFeeDetail({
          ...data,
          ...initialCollectionValues,
        });
      }
    } catch (error) {
      console.error("Error fetching student:", error);
    }
  };
  fetchInvoices();
}, [idf, token]);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFeeDetail((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleUpdate = async () => {
    // Add confirmation dialog
    const confirmUpdate = window.confirm("Are you sure you want to proceed with the update?");
    if (!confirmUpdate) {
        return; // Exit the function if the user cancels
    }

    const collectionAmount = parseFloat(feedetail.collection);
    // Check if the collection amount is valid
    if (isNaN(collectionAmount) || collectionAmount <= 0) {
        alert("Fee Collection Amount should be greater than 0.");
        return;
    }

    // Check if the collection_by is already set in the database
    if (feedetail.collection_by) {
        alert("This fee record has already been collected by someone else.");
        return;
    }

    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, "0")}-${String(currentDate.getDate()).padStart(2, "0")}`;

    const dataToUpdate = {
        collection: feedetail.collection,
        fine_collection: feedetail.fine_collection,
        adm_collection: feedetail.adm_collection,
        misc_collection: feedetail.misc_collection,
        exam_collection: feedetail.exam_collection,
        fine_arrears: feedetail.fine_arrears,
        collection_by: user.username,
        payment_at: formattedDate,
    };

    console.log("Data to update: ", dataToUpdate);

    try {
        const response = await axios.patch(
            `${process.env.REACT_APP_API_URL}/fee/${idf}`,
            dataToUpdate,
            { headers: { Authorization: `Bearer ${token}` } }
        );

        if (response.data.success) {
            alert("Update successful!");
            navigate("/dashboard/feesearchcollect");
        } else {
            alert("Update failed: " + response.data.message); // This now handles year/month mismatch errors appropriately
        }
    } catch (error) {
        console.error("Error updating fee detail:", error);
        alert("Error updating the fee detail.");
    }
};




  

  const handleback = (e) => {
    navigate("/dashboard/feesearchcollect");
  };

  return (
    <div className="container">
      <div className="row">
        <h2 className="text-center" style={{ color: "#0b4f6c" }}>
          Fee Collection
        </h2>
      </div>
      <div className="row">
        <table>
          <tbody>
            <tr>
              <td>
                <label className="form-control">Invoice</label>
              </td>
              <td>
                <input
                  type="text"
                  className="form-control"
                  name="idf"
                  value={feedetail?.idf || ""}
                  disabled
                /> 
              </td>
             
              <td>
                <label className="form-control">Name</label>
              </td>
              <td>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  value={feedetail?.name || ""}
                  disabled
                />
              </td>
              <td>
                <label className="form-control">Total Fee</label>
              </td>
              <td>
                <input
                  type="text"
                  className="form-control"
                  name="total_fee"
                  value={feedetail?.total_fee || ""}
                  disabled
                />
              </td>
            </tr>

            <tr>

              <td>
                <label className="form-control">Monthly Fee</label>
              </td>
              <td>
                <input
                  type="text"
                  className="form-control"
                  name="monthly_fee"
                  value={feedetail?.monthly_fee_feetbl || ""}
                  disabled
                />
              </td>
              <td>
              {/* Monthly Fee Arrears */}
                <label className="form-control">Arrears</label>
              </td>
              <td>
                <input
                  type="text"
                  className="form-control"
                  name="arrears"
                  value={feedetail?.arrears || ""}
                  disabled
                />
              </td>
             
              <td>
                <label className="form-control">Receive</label>
              </td>
              <td>
                <input
                  type="text"
                  className="form-control bg-success text-white"
                  name="collection"
                  value={feedetail?.collection || 0}
                  onChange={handleChange}
                />
              </td>
             
            </tr>
            <tr>
              <td>
                <label className="form-control">Misc Fee</label>
              </td>
              <td>
                <input
                  type="text"
                  className="form-control"
                  name="misc_fee"
                  value={feedetail?.misc_fee || 0}
                  disabled
                />
              </td>
              <td>
                <label className="form-control">Arrears</label>
              </td>
              <td>
                <input
                  type="text"
                  className="form-control"
                  name="misc_arrears"
                  value={feedetail?.misc_arrears || 0}
                  onChange={handleChange}
                  disabled
                />
              </td>
              <td>
                <label className="form-control">Receive</label>
              </td>
              <td>
                <input
                  type="text"
                  className="form-control bg-success text-white"
                  name="misc_collection"
                  value={feedetail?.misc_collection || 0}
                  onChange={handleChange}
                />
              </td>
             
              </tr>
              <tr>
              <td>
                <label className="form-control">Adm Fee</label>
              </td>
              <td>
                <input
                  type="text"
                  className="form-control"
                  name="adm_fee"
                  value={feedetail?.adm_fee || 0}
                  onChange={handleChange}
                  disabled
                />
              </td> 
              <td>
                <label className="form-control">Arrears</label>
              </td>
              <td>
                <input
                  type="text"
                  className="form-control"
                  name="adm_arrears"
                  value={feedetail?.adm_arrears || 0}
                  onChange={handleChange}
                  disabled
                />
              </td>
              <td>
                <label className="form-control">Receive</label>
              </td>
              <td>
                <input
                  type="text"
                  className="form-control bg-success text-white"
                  name="adm_collection"
                  value={feedetail?.adm_collection || 0}
                  onChange={handleChange}
                />
              </td>
              
              
            </tr>
            
            <tr>
            <td>
                <label className="form-control">Fine</label>
              </td>
              <td>
                <input
                  type="text"
                  className="form-control"
                  name="fine_fee"
                  value={feedetail?.fine_fee || 0}
                  onChange={handleChange}
                  disabled
                />
              </td>
            <td>
                <label className="form-control">Arrears</label>
              </td>
              <td>
                <input
                  type="text"
                  className="form-control"
                  name="fine_arrears"
                  value={feedetail?.fine_arrears || 0}
                  onChange={handleChange}
                  disabled
                />
              </td>
              <td>
                <label className="form-control">Receive</label>
              </td>
              <td>
                <input
                  type="text"
                  className="form-control bg-success text-white"
                  name="fine_collection"
                  value={feedetail?.fine_collection || 0}
                  onChange={handleChange}
                />
              </td>

            </tr>
            <tr>
              
            
              <td>
                <label className="form-control">Exam Fee</label>
              </td>
              <td>
                <input
                  type="text"
                  className="form-control"
                  name="exam_fee"
                  value={feedetail?.exam_fee || 0}
                  disabled
                />
              </td>
              
              <td>
                <label className="form-control">Arrears</label>
              </td>
              <td>
                <input
                  type="text"
                  className="form-control"
                  name="exam_arrears"
                  value={feedetail?.exam_arrears || 0}
                  onChange={handleChange}
                  disabled
                />
              </td>
              <td>
                <label className="form-control">Receive</label>
              </td>
              <td>
                <input
                  type="text"
                  
                  className="form-control bg-success text-white"
                  name="exam_collection"
                  value={feedetail?.exam_collection || 0}
                  onChange={handleChange}
                />
              </td>
              
            </tr>
           
            <tr>
              <td>
                <button className="btn btn-success text-center" onClick={handleUpdate}>
                  Update
                </button>
                </td><td>
                <button className="btn btn-secondary ml-2 text-center" onClick={handleback}>
                  Back
                </button>
              </td>
            </tr>
            </tbody>
        </table>
        <tr>
              <p className="text-right" style={{fontFamily:"Noori Nastaleeq", fontSize:"20px"}}>جیسا کہ آپ دیکھ رہے ہیں کہ  سبز رنگ والے ڈبے میں جو فیس جمع کرنی ہے وہ دکھائی دے رہی ہے، آپ اس کو تبدیل بھی کر کے اپ ڈیٹ کر سکتے ہیں۔ اور یاد رہے کہ اگر ایک بار آپ نے فیس جمع کر لی تو  اس انوائس کو دوبارہ آپ جمع نہیں کر سکیں گے۔</p>
            
            </tr>
         
      </div>
    </div>
  );
}
