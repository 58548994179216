import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import userContext from "../context/UserContext";

const FeeEdit = () => {
  const navigate = useNavigate();
  const { token } = useContext(userContext);
  const { idf } = useParams();
  const [feeDetails, setFeeDetails] = useState({
    monthly_fee: "",
    fine_fee: "",
    exam_fee: "",
    misc_fee: "",
    arrears: "",
    adm_fee: "",            // New field
    fine_arrears: "",       // New field
    adm_arrears: "",        // New field
    exam_arrears: "",       // New field
    misc_arrears: "",       // New field
  });
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchFeeDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/fee/edit/${idf}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data.data) {
          setFeeDetails(response.data.data);
        } else {
          console.log("No data found");
        }
      } catch (error) {
        setError(error);
        console.error("Error fetching fee details:", error);
      }
    };
    fetchFeeDetails();
  }, [idf, token]);

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      await axios.patch(
        `${process.env.REACT_APP_API_URL}/fee/edit/${idf}`,
        feeDetails,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      navigate("/dashboard/feesearchcollect");
      alert("Fee updated successfully");
    } catch (error) {
      setError(error);
      console.error("Error updating fee:", error);
    }
  };

  const handleChange = (e) => {
    setFeeDetails({ ...feeDetails, [e.target.name]: e.target.value });
  };

  const handleback = (e) => {
    navigate("/dashboard/feesearchcollect");
  };

  return (
    <div>
      {error && <div className="alert alert-danger">{error.toString()}</div>}
      <div className="row">
        <h2
          className="text-center"
          style={{ textDecoration: "underline", color: "#0b4f6c" }}
        >
          Edit Adm No: {feeDetails.fee_adm_no} {feeDetails.name}'s Fee for
          Month: {feeDetails.fmonth} of {feeDetails.fyear}
        </h2>
      </div>
      <form onSubmit={handleUpdate}>
        <table>
          <tbody>
          <h2> Fee Detail</h2>
            <tr>
              <td>
                <label>Monthly Fee:</label>
              </td>
              <td>
                <input
                  type="number"
                  name="monthly_fee"
                  value={feeDetails.monthly_fee}
                  onChange={handleChange}
                  className="form-control"
                />
              </td>
              <td>
                <label>Fine Fee:</label>
              </td>
              <td>
                <input
                  type="number"
                  name="fine_fee"
                  value={feeDetails.fine_fee}
                  onChange={handleChange}
                  className="form-control"
                />
              </td>
              <td>
                <label>Exam Fee:</label>
              </td>
              <td>
                <input
                  type="number"
                  name="exam_fee"
                  value={feeDetails.exam_fee}
                  onChange={handleChange}
                  className="form-control"
                />
              </td>
            </tr>
            <tr>
             
              <td>
                <label>Misc Fee:</label>
              </td>
              <td>
                <input
                  type="number"
                  name="misc_fee"
                  value={feeDetails.misc_fee}
                  onChange={handleChange}
                  className="form-control"
                  disabled
                />
              </td>
              <td>
                <label>Adm Fee:</label>
              </td>
              <td>
                <input
                  type="number"
                  name="adm_fee"
                  value={feeDetails.adm_fee}
                  onChange={handleChange}
                  className="form-control"
                />
              </td>
              <td>
                <label><b>Total Fee:</b></label>
              </td>
              <td>
                
                <input
                  type="number"
                  name="adm_fee"
                  value={feeDetails.total_fee}
                  onChange={handleChange}
                  className="form-control"
                  disabled
                />
              </td>
            </tr>
           <h2> Arrears</h2>
            <tr>
             
              <td>
                <label>M/Fee Arrears:</label>
              </td>
              <td>
                <input
                  type="number"
                  name="arrears"
                  value={feeDetails.arrears}
                  onChange={handleChange}
                  className="form-control"
                />
              </td>
              <td>
                <label>Fine Arrears:</label>
              </td>
              <td>
                <input
                  type="number"
                  name="fine_arrears"
                  value={feeDetails.fine_arrears}
                  onChange={handleChange}
                  className="form-control"
                />
              </td>
              <td>
                <label>Adm Arrears:</label>
              </td>
              <td>
                <input
                  type="number"
                  name="adm_arrears"
                  value={feeDetails.adm_arrears}
                  onChange={handleChange}
                  className="form-control"
                />
              </td>
            </tr>
            <tr>
              <td>
                <label>Exam Arrears:</label>
              </td>
              <td>
                <input
                  type="number"
                  name="exam_arrears"
                  value={feeDetails.exam_arrears}
                  onChange={handleChange}
                  className="form-control"
                />
              </td>
              <td>
                <label>Misc Arrears:</label>
              </td>
              <td>
                <input
                  type="number"
                  name="misc_arrears"
                  value={feeDetails.misc_arrears}
                  onChange={handleChange}
                  className="form-control"
                />
              </td>
              <td>
                <label>Total Arrears:</label>
              </td>
              <td>
                <input
                  type="number"
                  name="misc_arrears"
                  value={feeDetails.total_arrears}
                  onChange={handleChange}
                  className="form-control"
                  disabled
                />
              </td>
            </tr>
          </tbody>
        </table>

        <button type="submit" className="btn btn-primary">
          Update Fee
        </button>
        <button className="btn btn-secondary ml-2" onClick={handleback}>
          Back
        </button>
      </form>
    </div>
  );
};

export default FeeEdit;
