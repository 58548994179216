import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import userContext from '../context/UserContext';

const AddQuestion = () => {
    const [standard, setStandard] = useState('');
    const [standards, setStandards] = useState([]);
    const [subject, setSubject] = useState('');
    const [chapter, setChapter] = useState('');
    const [qType, setQType] = useState('');
    const [question, setQuestion] = useState('');
    const [options, setOptions] = useState({ opt1: '', opt2: '', opt3: '', opt4: '' }); // Initialize options state
    const { token } = useContext(userContext);
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    // Fetch standards from the backend on component mount
    useEffect(() => {
        const fetchStandards = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/classes`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setStandards(response.data);
            } catch (error) {
                console.error('Error fetching standards:', error);
                setErrorMessage('Failed to fetch standards');
            }
        };
        
        fetchStandards();
    }, [token]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Include options only if qType is "Multiple Choice"
            const requestBody = {
                q_standard: standard,
                subject,
                chapter,
                q_type: qType,
                question,
            };
    
            if (qType === "Multiple Choice") {
                requestBody.opt1 = options.opt1;
                requestBody.opt2 = options.opt2;
                requestBody.opt3 = options.opt3;
                requestBody.opt4 = options.opt4;
            }
    
            await axios.post(`${process.env.REACT_APP_API_URL}/paperserver/addq`, requestBody, {
                headers: { Authorization: `Bearer ${token}` },
            });
            
            setSuccessMessage('Question added successfully!');
            setTimeout(() => {
                navigate('/dashboard/questions'); // Redirect after success
            }, 2000);
        } catch (error) {
            console.error('Error adding question:', error);
            setErrorMessage('Error adding question');
        }
    };
    

    return (
        <div className="d-flex flex-column align-items-center " style={{ minHeight: '100vh' }}>
            <div className="card col-md-8">
                <div className="card-header text-center">
                    <h1>Add New Question</h1>
                    {errorMessage && <div className="alert alert-danger mt-3">{errorMessage}</div>}
                    {successMessage && <div className="alert alert-success mt-3">{successMessage}</div>}
                </div>
                <div className="card-body">
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label htmlFor="standard"><b>Standard</b></label>
                            <select
                                className="form-control"
                                value={standard}
                                onChange={(e) => setStandard(e.target.value)}
                                required
                            >
                                <option value="">Select Standard</option>
                                {standards.map((std) => (
                                    <option key={std.sid} value={std.sid}>{std.standard}</option>
                                ))}
                            </select>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="subject"><b>Subject</b></label>
                            <input
                                type="text"
                                className="form-control"
                                value={subject}
                                onChange={(e) => setSubject(e.target.value)}
                                required
                                autoComplete='off'
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="chapter"><b>Chapter</b></label>
                            <input
                                type="text"
                                className="form-control"
                                value={chapter}
                                onChange={(e) => setChapter(e.target.value)}
                                required
                                autoComplete='off'
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="qType"><b>Question Type</b></label>
                            <select
                                className="form-control"
                                value={qType}
                                onChange={(e) => {
                                    setQType(e.target.value);
                                    if (e.target.value !== "Multiple Choice") {
                                        setOptions({ opt1: '', opt2: '', opt3: '', opt4: '' }); // Clear options if not multiple choice
                                    }
                                }}
                                required
                            >
                                <option value="">Select Question Type</option>
                                <option value="Short">Short</option>
                                <option value="Long">Long</option>
                                <option value="Fill in blanks">Fill in blanks</option>
                                <option value="True / False">True / False</option>
                                <option value="Multiple Choice">Multiple Choice</option>
                            </select>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="question"><b>Question</b></label>
                            <textarea
                                className="form-control"
                                value={question}
                                onChange={(e) => setQuestion(e.target.value)}
                                required
                                autoComplete='off'
                            />
                        </div>

                        {/* Conditional rendering for options */}
                        {qType === "Multiple Choice" && (
                            <>
                                <div className="mb-3">
                                    <label htmlFor="opt1"><b>Option 1</b></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={options.opt1}
                                        onChange={(e) => setOptions({ ...options, opt1: e.target.value })}
                                        required
                                        autoComplete='off'
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="opt2"><b>Option 2</b></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={options.opt2}
                                        onChange={(e) => setOptions({ ...options, opt2: e.target.value })}
                                        required
                                        autoComplete='off'
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="opt3"><b>Option 3</b></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={options.opt3}
                                        onChange={(e) => setOptions({ ...options, opt3: e.target.value })}
                                        required
                                        autoComplete='off'
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="opt4"><b>Option 4</b></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={options.opt4}
                                        onChange={(e) => setOptions({ ...options, opt4: e.target.value })}
                                        required
                                        autoComplete='off'
                                    />
                                </div>
                            </>
                        )}
                        <button type="submit" className="btn btn-success">Add Question</button>
                        <button type="button" className="btn btn-secondary ml-1" onClick={() => navigate('/dashboard/questions')}>Back</button>
                        <button type="button" className="btn btn-primary ml-1" onClick={() => navigate('/dashboard/addqtopaper')}>Initiate Paper</button>
                        <button type="button" className="btn btn-success ml-1" onClick={() => navigate('/dashboard/paper')}>Download Paper </button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default AddQuestion;
