import React, { createContext, useState, useContext } from 'react';

// Create context
const InvoicesContext = createContext();

export const useInvoices = () => {
  return useContext(InvoicesContext);
};

export const InvoicesProvider = ({ children }) => {
  const [fetchedInvoices, setFetchedInvoices] = useState([]);
  const [searchParams, setSearchParams] = useState({
    searchValue: '',
    searchYear: '',
    searchMonth: '',
  });

  const updateSearchParams = (newParams) => {
    setSearchParams(prev => ({ ...prev, ...newParams }));
  };

  return (
    <InvoicesContext.Provider value={{ fetchedInvoices, setFetchedInvoices, searchParams, updateSearchParams }}>
      {children}
    </InvoicesContext.Provider>
  );
};
