import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import userContext from '../context/UserContext';

const Datesheet = () => {
  const { token } = useContext(userContext);
  const navigate = useNavigate();
  const [subject, setSubject] = useState('');
  const [date, setDate] = useState('');
  const [year, setYear] = useState('');
  const [exam, setExam] = useState('');
  const [standard, setStandard] = useState(''); // New state for Standard
  const [yearOptions, setYearOptions] = useState([]);
  const [examOptions, setExamOptions] = useState([]);
  const [standardOptions, setStandardOptions] = useState([]); // State for Standard options

  useEffect(() => {
    const fetchYears = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/datesheet/years`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setYearOptions(response.data.map(option => option.year));
      } catch (error) {
        console.error('Error fetching years:', error);
      }
    };

    const fetchExams = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/datesheet/exams`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setExamOptions(response.data.map(option => option.exam || option.month));
      } catch (error) {
        console.error('Error fetching exams:', error);
      }
    };

    const fetchStandards = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/datesheet/standards`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setStandardOptions(response.data.map(option => option.standard)); // Adjust according to your API response structure
      } catch (error) {
        console.error('Error fetching standards:', error);
      }
    };

    fetchYears();
    fetchExams();
    fetchStandards(); // Fetch standards when component mounts
  }, [token]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Submitting:', { subject, date, year, exam, standard }); // Add to log
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/datesheet/add`,
        { subject, date, year, exam, standard }, // Include standard in the submission
        { headers: { Authorization: `Bearer ${token}` } },
      );
      alert('Data saved successfully!');
      // Reset fields
      // setSubject('');
      setDate('');
      // setYear('');
      // setExam('');
      // setStandard('');
    } catch (error) {
      console.error('Error submitting date sheet:', error.response.data);
      alert('Error occurred while saving data: ' + error.response.data.error);
    }
  };
   const handleclick = ()=>{
    navigate('/dashboard/datesheetprint');
   }
  

  return (
    <>
      <h2 className='text-center'>PREPARE DATE SHEET</h2>
     <hr/>
      <div className='row'>
      
        <form onSubmit={handleSubmit} className="row">
          <div className="col-md-2 mb-3">
            <label className='ml-4'><strong>Subject</strong></label>
            <input
              type='text'
              value={subject}
              className="form-control mx-2 shadow p-2 rounded-pill border-3 border-info"
              onChange={(e) => setSubject(e.target.value)}
              required
            />
          </div>

          <div className="col-md-2 mb-3">
            <labe className='ml-4'><strong>Date</strong></labe>
            <input
              type='date'
              value={date}
              className="form-control mx-2 shadow p-2 rounded-pill border-3 border-info"
              onChange={(e) => setDate(e.target.value)}
              required
            />
          </div>

          <div className="col-md-2 mb-3">
            <label className='ml-4'><strong>Year</strong></label>
            <select
              value={year}
              className="form-control mx-2 shadow p-2 rounded-pill border-3 border-info"
              onChange={(e) => setYear(e.target.value)}
              required
            >
              <option value=''>Select Year</option>
              {yearOptions.map((yearOption, index) => (
                <option key={index} value={yearOption}>
                  {yearOption}
                </option>
              ))}
            </select>
          </div>

          <div className="col-md-2 mb-3">
            <label className='ml-4'><strogn>Exam</strogn></label>
            <select
              value={exam}
              className="form-control mx-2 shadow p-2 rounded-pill border-3 border-info"
              onChange={(e) => setExam(e.target.value)}
              required
            >
              <option value=''>Select Exam</option>
              {examOptions.map((examOption, index) => (
                <option key={index} value={examOption}>
                  {examOption}
                </option>
              ))}
            </select>
          </div>

          <div className="col-md-2 mb-3">
            <label className='ml-4'><strong>Standard</strong></label>
            <select
              value={standard}
              className="form-control mx-2 shadow p-2 rounded-pill border-3 border-info"
              onChange={(e) => setStandard(e.target.value)}
              required
            >
              <option value=''>Select Standard</option>
              {standardOptions.map((standardOption, index) => (
                <option key={index} value={standardOption}>
                  {standardOption}
                </option>
              ))}
            </select>
          </div>
          <div className='row'>
            <div className='col-md-2'>
          <button className='btn btn-primary ml-4' type='submit'>
            Submit
          </button>

            </div>

          </div>

        </form>
        <div>
        
        </div>
      </div>
      <div>
      <button className='btn btn-success mt-2 ml-4 ' onClick={handleclick}>View Date Sheet</button>
      </div>
    </>
  );
};

export default Datesheet;
