import { Document, Page, StyleSheet, View, Text } from "@react-pdf/renderer";
import React from "react";

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#ffffff",
    padding: 10,
  },
  header: {
    fontSize: 14,
    marginBottom: 5,
    textAlign: "center",
    fontWeight: "bold",
  },
  table: {
    display: "table",
    width: "100%",
    marginTop: 5,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000",
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
  },
  tableCol: {
    width: "20%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000",
    padding: 5,
    textAlign: "center",
    fontSize: 10,
  },
  tableHeader: {
    backgroundColor: "#f0f0f0",
    fontWeight: "bold",
    fontSize: 10,
  },
  tableCell: {
    fontSize: 10,
  },
});

const UnpaidFeePdf = ({ data, filters }) => {
  if (!data || data.length === 0) return <Text>No Data Available</Text>;

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Text style={styles.header}>Unpaid Fee Report</Text>
        <View style={styles.tableRow}>
          <Text style={styles.tableCol}>Class: {filters.selectedStandard || "All"}</Text>
          <Text style={styles.tableCol}>Year: {filters.selectedYear || "All"}</Text>
          <Text style={styles.tableCol}>Month: {filters.selectedMonth || "All"}</Text>
        </View>

        {/* Table Header */}
        <View style={styles.table}>
          <View style={[styles.tableRow, styles.tableHeader]}>
            <Text style={styles.tableCol}>Id</Text>
            <Text style={styles.tableCol}>Adm No</Text>
            <Text style={styles.tableCol}>Name</Text>
            <Text style={styles.tableCol}>Standard</Text>
            <Text style={styles.tableCol}>Total Fee</Text>
          </View>

          {/* Table Data */}
          {data.map((detail, index) => (
            <View style={styles.tableRow} key={detail.idf}>
              <Text style={styles.tableCol}>{detail.idf}</Text>
              <Text style={styles.tableCol}>{detail.fee_adm_no}</Text>
              <Text style={styles.tableCol}>{detail.name}</Text>
              <Text style={styles.tableCol}>{detail.FeeStandard}</Text>
              <Text style={styles.tableCol}>{detail.total_fee}</Text>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
};

export default UnpaidFeePdf;
