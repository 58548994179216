import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import userContext from '../context/UserContext';
import { useNavigate } from "react-router-dom"; 

const StudentPromote = () => {
  const [students, setStudents] = useState([]);
  const [standards, setStandards] = useState([]);
  const [selectedStandards, setSelectedStandards] = useState(new Map());
  const [searchTerm, setSearchTerm] = useState('');
  const [newStandard, setNewStandard] = useState('');
  
  const navigate = useNavigate(); 
  const { token } = useContext(userContext);

  // Fetch students and standards once at the start
  useEffect(() => {
    const fetchStudents = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/students`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setStudents(response.data);
      } catch (error) {
        console.error("Error fetching students:", error);
      }
    };

    const fetchStandards = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/classes`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setStandards(response.data);
      } catch (error) {
        console.error("Error fetching standards:", error);
      }
    };

    fetchStudents();
    fetchStandards();
  }, [token]);

  // Function to handle checkbox selection
  const handleCheckboxChange = (admNo) => {
    setSelectedStandards(prev => {
      const updatedStandards = new Map(prev);
      if (updatedStandards.has(admNo)) {
        updatedStandards.delete(admNo);
      } else {
        updatedStandards.set(admNo, true); // Mark as selected
      }
      return updatedStandards;
    });
  };

  // Update students' standards
  const handleUpdate = async () => {
    const updates = Array.from(selectedStandards.keys()).map(admNo => ({
      adm_no: admNo,
      standard: newStandard,
    }));
  
    try {
      await axios.patch(`${process.env.REACT_APP_API_URL}/promote/update`, updates, {
        headers: { Authorization: `Bearer ${token}` },
      });
      alert("Standards updated successfully!");
      navigate(`/dashboard/studentlist`);
    } catch (error) {
      console.error("Error updating standards:", error);
      alert("Failed to update standards.");
    }
  };

  // Filter students by search term
  const filteredStudents = students.filter(student => {
    const { standard, adm_no, name } = student; // Destructure properties for easier access
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    return (
      standard.toLowerCase().includes(lowerCaseSearchTerm) ||
      adm_no.toString().toLowerCase().includes(lowerCaseSearchTerm) ||
      name.toLowerCase().includes(lowerCaseSearchTerm)
    );
  });

  return (
    <div className='container'>
      <h3 className="text-center mb-4" style={{color: "#0b4f6c"}}>STUDENT PROMOTION / DEMOTION</h3>
      <div className='row'>
        <div className='col-md-4'>
          {/* Search Bar */}
          <input 
            type="text" 
            className="form-control mx-2 shadow p-2 rounded-pill border-3 border-info" 
            placeholder="Filter Students by standard, adm no, or name..." 
            value={searchTerm} 
            onChange={(e) => setSearchTerm(e.target.value)} 
          />
        </div>
        <div className='col-md-4 text-right'>
          <label htmlFor="newStandard" className="form-label bg-[#0b4f6c] text-white mx-2 shadow p-2 rounded-pill">
            <b>Select Standard </b>
          </label>
        </div>
        <div className='col-md-4'>
          <select 
            id="newStandard" 
            className="form-select mx-2 shadow p-2 rounded-pill border-3 border-info" 
            value={newStandard} 
            onChange={(e) => setNewStandard(e.target.value)}
          >
            <option value="">Select Standard</option>
            {standards.map(std => (
              <option key={std.sid} value={std.standard}>{std.standard}</option>
            ))}
          </select>
        </div>
      </div>
      <button className="btn btn-success" onClick={handleUpdate} disabled={!newStandard}>Update</button>
      
      <table className="table">
        <thead>
          <tr>
            <th>Select</th>
            <th>Adm No</th>
            <th>Name</th>
            <th>Current Standard</th>
          </tr>
        </thead>
        <tbody>
          {filteredStudents.map(student => (
            <tr key={student.adm_no}>
              <td>
                <input
                  type="checkbox"
                  onChange={() => handleCheckboxChange(student.adm_no)}
                  checked={selectedStandards.has(student.adm_no)}
                />
              </td>
              <td>{student.adm_no}</td>
              <td>{student.name}</td>
              <td>{student.standard}</td> {/* Show current standard of the student */}
            </tr>
          ))}
        </tbody>
      </table>
      
    </div>
  );
};

export default StudentPromote;
