import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import userContext from '../context/UserContext';

const TaskEdit = () => {
    const { syllabus_id } = useParams();
   
    const [selectedStandard, setSelectedStandard] = useState('');
    const [subject, setSubject] = useState('');
    const [task, setTask] = useState('');
    const { token } = useContext(userContext);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchTaskData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/tasks/${syllabus_id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

                console.log("Fetched task data:", response.data); // Should include standards
              
                setSelectedStandard(response.data.task_standard); // Assuming this gives you the selected standard
                setSubject(response.data.subject);
                setTask(response.data.task);
            } catch (error) {
                console.error("Error fetching task data:", error.response ? error.response.data : error.message);
            }
        };

        fetchTaskData();
    }, [syllabus_id, token]);

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent default form submission
        try {
            const updatedTask = {
                standard: selectedStandard,
                subject,
                task,
            };

            await axios.patch(`${process.env.REACT_APP_API_URL}/tasks/${syllabus_id}`, updatedTask, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            // Redirect after successful update
            navigate(`/dashboard/assigntasks`); // Redirect to the specific task or tasks list page
        } catch (error) {
            console.error("Error updating task:", error.response ? error.response.data : error.message);
        }
    };

    return (
        <div>
            <h2>Edit Task</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group mb-3">
                    <label><b>Standard</b></label>
                    <input 
                    disabled
                        value={selectedStandard} 
                        onChange={(e) => setSelectedStandard(e.target.value)} 
                        className="form-control"
                    >

                    </input>
                </div>

                <div className="form-group mb-3">
                    <label><b>Subject</b></label>
                    <input 
                        type="text" 
                        value={subject} 
                        onChange={(e) => setSubject(e.target.value)} 
                        className="form-control" 
                        placeholder="Enter subject" 
                    />
                </div>

                <div className="form-group mb-3">
                    <label><b>Task</b></label>
                    <input 
                        type="text" 
                        value={task} 
                        onChange={(e) => setTask(e.target.value)} 
                        className="form-control" 
                        placeholder="Enter task" 
                    />
                </div>

                <button type="submit" className="btn btn-primary">Update Task</button>
                <button onClick={() => navigate('/dashboard/assigntasks')} className="btn btn-secondary ml-1">Back</button>
            </form>     
        </div>
    );
};

export default TaskEdit;
