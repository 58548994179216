import React, { useState } from "react";
import Sidebar from "./SideBar";
import { Route, Routes } from "react-router-dom";
import StudentList from "../students/StudentList";
import StudentCreate from "../students/StudentCreate";
import StudentPromote from "../students/Studentpromote";
import StudentEdit from "../students/StudentEdit";
import FeeDetail from "../Fee/FeeDetail";
import FeeCollection from "../Fee/FeeCollection";
import FeePaid from "../Fee/FeePaid";
import UnpaidFee from "../Fee/UnpaidFee";
import Standards from "../standards/standards";
import Standardedit from "../standards/standardedit";
import Standardcreate from "../standards/standardcreate";
import StaffList from "../Staff/StaffList";
import StaffAdd from "../Staff/StaffAdd";
import StaffEdit from "../Staff/StaffEdit";
import Salary from "../salaries/Salary";
import Leaves from "../salaries/Leaves";
import Attendance from "../Attendance/Attendance";
import AttReport from "../Attendance/AttReport";
import ResultPrep from "../Result/ResultPrep";
import Result from "../Result/Result";
import ResultObtMarks from "../Result/ResultObtMarks";
import PRPdf from "../Result/PRPdf";
import AssignTasks from "../Assignments/AssignTasks";
import TaskCreate from "../Assignments/TaskCreate";
import User from "../User/User";
import FeeMisc from "../Fee/FeeMisc";
import FeeEdit from "../Fee/FeeEdit";
import Registration from "../Registration";
import FeeSearchCollect from "../Fee/FeeSearchCollect";
import TaskEdit from "../Assignments/Taskedit";
import Main from "./Main";
import Report from "./Report";
import Datesheet from "../DateSheet/datesheet";
import Datesheetprint from "../DateSheet/datesheetprint";
import CreateAnnouncement from "../Pages/CreateAnnouncement";
import ListAnnouncement from "../Pages/AnnouncementsList";
import EditAnnouncement from "../Pages/EditAnnouncement";
import AddQuestion from "../Questions/AddQuestion";
import AddQtoPaper from "../Questions/AddQtoPaper";
import Paper from "../Questions/Paper";




const Dashboard = () => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleSidebar = () => setIsOpen(!isOpen);

    return (
      <div className="container-fluid">
        <Sidebar toggle={toggleSidebar} isOpen={isOpen} />
        <div className={`main ${isOpen ? "sidebar-open" : "sidebar-closed"}`}>
          <Routes>
            <Route index element={<StudentList />} />
            <Route path="studentlist" element={<StudentList />} />
            <Route path="studentcreate" element={<StudentCreate />} />
            <Route path="studentedit/:id" element={<StudentEdit />} />
            <Route path="feedetail" element={<FeeDetail />} />
            <Route path="feecollection/:idf" element={<FeeCollection />} />
            <Route path="feepaid" element={<FeePaid />} />
            <Route path="feesearchcollect" element={<FeeSearchCollect />} />
            <Route path="unpaidfee" element={<UnpaidFee />} />
            <Route path="standards" element={<Standards />} />
            <Route path="standardedit/:sid" element={<Standardedit />} />
            <Route path="standardcreate" element={<Standardcreate />} />
            <Route path="stafflist" element={<StaffList />} />
            <Route path="staffadd" element={<StaffAdd />} />
            <Route path="staffedit/:staffid" element={<StaffEdit />} />
            <Route path="salary" element={<Salary />} />
            <Route path="leaves/:salaryid" element={<Leaves />} />
            <Route path="attendance" element={<Attendance />} />
            <Route path="attreport" element={<AttReport />} />
            <Route path="resultprep" element={<ResultPrep />} />
            <Route path="result" element={<Result />} />
            <Route path="resultobtmarks/:resultid" element={<ResultObtMarks />} />
            <Route path="PRPdf/:resultid" element={<PRPdf />} />
            <Route path="assigntasks" element={<AssignTasks />} />
            <Route path="taskcreate" element={<TaskCreate />} />
            <Route path="user" element={<User />} />
            <Route path="feemisc" element={<FeeMisc />} />
            <Route path="feeedit/:idf" element={<FeeEdit />} />
            <Route path="registration" element={<Registration />} />          
            <Route path="taskedit/:syllabus_id" element={<TaskEdit />} />
            <Route path="main" element={<Main />} />
            <Route path="report" element={<Report />} />
            <Route path="datesheet" element={<Datesheet />} />
            <Route path="datesheetprint" element={<Datesheetprint />} />
            <Route path="createannouncement" element={<CreateAnnouncement />} />
            <Route path="announcementslist" element={<ListAnnouncement />} />
            <Route path="editannouncement/:id" element={<EditAnnouncement />} />
            <Route path="addquestion" element={<AddQuestion />} />
            <Route path="addqtopaper" element={<AddQtoPaper />} />
            <Route path="paper" element={<Paper />} />
            <Route path="studentpromote" element={<StudentPromote />} />
     
          
            
          </Routes>
        </div>
      </div>
    );
};

export default Dashboard;
