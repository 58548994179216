import { Document, Page, StyleSheet, View, Text } from "@react-pdf/renderer";
import React from "react";

// Styles for the PDF
const styles = StyleSheet.create({
  page: {
    backgroundColor: "#ffffff",
    padding: 20,
  },
  title: {
    fontSize: 24,
    marginBottom: 20,
    textAlign: "center",
    fontWeight: "bold",
  },
  section: {
    marginBottom: 10,
  },
  header: {
    fontSize: 18,
    marginBottom: 10,
    textAlign: "center",
    fontWeight: "bold",
  },
  boldQuestion: {
    fontSize: 12,
    marginBottom: 5,
    fontWeight: "bold",
  },
  question: {
    fontSize: 12,
    marginBottom: 5,
    marginLeft: 10,
  },
  address: {
    fontSize: 12,
    marginBottom: 5,
    marginLeft: 10,
    textAlign:"center"
  },
  marks: {
    fontSize: 12,
    fontWeight: "bold",
    marginLeft: 20,
  },
  table: {
    display: "table",
    width: "auto",
    margin: 5,
  },
  tableRow: {
    flexDirection: "row",
    borderBottomWidth: 0,
    borderColor: "white",
  },
  tableCol: {
    width: "80%",
    padding: 5,
    fontSize: 12,
    marginTop: 5, // Increased margin
  },
  tableSer: {
    width: "5%",
    fontSize: 12,
    marginLeft:10,
    padding: 5,
    fontFamily: "Helvetica",
    marginTop: 5, // Increased margin
  },
  options: {
    marginLeft: 40,
    fontSize: 12,
    
  },
});

// Custom component for PDF
const PaperPdf = ({ questions, subject }) => {
  // Separate the questions based on type
  const objectiveQuestions = questions.filter(q => ['Fill in blanks', 'True / False', 'Multiple Choice'].includes(q.q_type));
  const subjectiveQuestions = questions.filter(q => ['Short', 'Long'].includes(q.q_type));
  
  // Calculate total marks
  const calculateTotalMarks = (questions) => questions.reduce((total, q) => total + (q.q_marks || 0), 0);

  // Function to generate alphabetic letters (a, b, c, ...)
  const getLetter = (index) => String.fromCharCode(97 + index); // 97 is the ASCII code for 'a'

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Text style={styles.title}>My School Name</Text>
        <Text style={styles.address}>School Address & Contact Number</Text>
        <Text style={styles.question}>{subject}</Text>

        {/* Objective Part Section */}
        <View style={styles.section}>
          <Text style={styles.header}>OBJECTIVE</Text>
          <Text style={styles.boldQuestion}>
            Total Marks: {calculateTotalMarks(objectiveQuestions)}
          </Text>
          
          {/* Fill in Blanks */}
          {objectiveQuestions.some(q => q.q_type === 'Fill in blanks') && (
            <View style={styles.table}>
              <Text style={styles.boldQuestion}>
                1. Fill in blanks: (Marks: {calculateTotalMarks(objectiveQuestions.filter(q => q.q_type === 'Fill in blanks'))})
              </Text>
              {objectiveQuestions.filter(q => q.q_type === 'Fill in blanks').map((q, index) => (
                <View style={styles.tableRow} key={q.qid}>
                  <Text style={styles.tableSer}>{getLetter(index)}</Text>
                  <Text style={styles.tableCol}>{q.question}</Text>
                  <Text style={styles.tableSer}>{q.q_marks || 0}</Text>
                </View>
              ))}
            </View>
          )}

          {/* Multiple Choice */}
          {objectiveQuestions.some(q => q.q_type === 'Multiple Choice') && (
            <View style={styles.table}>
              <Text style={styles.boldQuestion}>
                2. Choose the correct option from the following: (Marks: {calculateTotalMarks(objectiveQuestions.filter(q => q.q_type === 'Multiple Choice'))})
              </Text>
              {objectiveQuestions.filter(q => q.q_type === 'Multiple Choice').map((q, index) => (
                <View key={q.qid}>
                  <View style={styles.tableRow}>
                    <Text style={styles.tableSer}>{getLetter(index)}</Text>
                    <Text style={styles.tableCol}>{q.question}</Text>
                    <Text style={styles.tableSer}>{q.q_marks || 0}</Text>
                  </View>
                  <Text style={styles.options}>
                    {`i. ${q.opt1 || ''}   | ii. ${q.opt2 || ''}   | iii. ${q.opt3 || ''}   | iv. ${q.opt4 || ''}`}
                  </Text>
                </View>
              ))}
            </View>
          )}

          {/* True / False */}
          {objectiveQuestions.some(q => q.q_type === 'True / False') && (
            <View style={styles.table}>
              <Text style={styles.boldQuestion}>
                3. Mark Tick for True and X for False: (Marks: {calculateTotalMarks(objectiveQuestions.filter(q => q.q_type === 'True / False'))})
              </Text>
              {objectiveQuestions.filter(q => q.q_type === 'True / False').map((q, index) => (
                <View style={styles.tableRow} key={q.qid}>
                  <Text style={styles.tableSer}>{getLetter(index)}</Text>
                  <Text style={styles.tableCol}>{q.question}</Text>
                  <Text style={styles.tableSer}>{q.q_marks || 0}</Text>
                </View>
              ))}
            </View>
          )}
        </View>

        {/* Subjective Part Section */}
        <View style={styles.section}>
          <Text style={styles.header}>SUBJECTIVE</Text>
          <Text style={styles.boldQuestion}>
            Total Marks: {calculateTotalMarks(subjectiveQuestions)}
          </Text>
          {subjectiveQuestions.length > 0 ? (
            <View style={styles.table}>
              {subjectiveQuestions.map((q, index) => (
                <View style={styles.tableRow} key={q.qid}>
                  <Text style={styles.tableSer}>{index + 1}</Text>
                  <Text style={styles.tableCol}>{q.question}</Text>
                  <Text style={styles.tableSer}>{q.q_marks || 0}</Text>
                </View>
              ))}
            </View>
          ) : (
            <Text style={styles.question}>No subjective questions found.</Text>
          )}
        </View>
      </Page>
    </Document>
  );
};

export default PaperPdf;
