import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import userContext from '../context/UserContext';

const AnnouncementsList = () => {
  const [announcements, setAnnouncements] = useState([]);
  const { token } = useContext(userContext);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAnnouncements = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/info`, { 
          headers: { 'Authorization': `Bearer ${token}` }
        });
        setAnnouncements(response.data);
      } catch (err) {
        console.error("Error fetching announcements:", err);
      }
    };
    fetchAnnouncements();
  }, [token]);

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/info/${id}`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      setAnnouncements(announcements.filter(announcement => announcement.id !== id));
    } catch (err) {
      console.error("Error deleting announcement:", err);
    }
  };

  return (
    <div className="card col-md-12 mx-auto">
      <div className="card-header">
        <h2 className="text-center">Announcements</h2>
        <button className="btn btn-success" onClick={() => navigate('/dashboard/createannouncement')}>Create New</button>
      </div>
      <div className="card-body">
        <table className="table">
          <thead>
            <tr>
              <th>Title</th>
              <th>Description</th>
              <td>Image</td>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {announcements.map(announcement => (
              <tr key={announcement.id}>
                <td>{announcement.title}</td>
                <td>{announcement.description}</td>
                <td>
                  {announcement.image && (
                    <img src={
                      `${process.env.REACT_APP_API_URL}/${announcement.image}`} alt={announcement.title} style={{ width: '100px', height: '100px' }} />
                  )}
                </td>
                <td>
                  <button className="btn btn-warning" onClick={() => navigate(`/dashboard/editannouncement/${announcement.id}`)}>Edit</button>
                  <button className="btn btn-danger" onClick={() => handleDelete(announcement.id)}>Delete</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AnnouncementsList;
