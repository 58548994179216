import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import userContext from "../context/UserContext";

export default function FeeMisc() {
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { token } = useContext(userContext);
  const [standards, setStandards] = useState([]);
  const [selectedStandard, setSelectedStandard] = useState("");
  const [examFeeValue, setExamFeeValue] = useState(0);
  const [miscFeeValue, setMiscFeeValue] = useState(0); // New state for misc fee
  const [remarksValue, setRemarksValue] = useState(""); // New state for remarks

  useEffect(() => {
    const fetchStandards = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/classes`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.data && Array.isArray(response.data)) {
          setStandards(response.data.map((item) => item.standard));
        } else {
          console.error("Expected an array of standards, but got:", response.data);
        }
      } catch (error) {
        setError("Error fetching standards. Please try again later.");
        console.error("Error fetching standards:", error);
      }
    };
    fetchStandards();
  }, [token]);

  const handleStandardChange = (e) => setSelectedStandard(e.target.value);
  const handleExamFeeChange = (e) => setExamFeeValue(e.target.value);
  const handleMiscFeeChange = (e) => setMiscFeeValue(e.target.value);
  const handleRemarksChange = (e) => setRemarksValue(e.target.value);

  const handleExamFeeUpdate = async () => {
    try {
      const data = {
        standard: selectedStandard,
        examFee: examFeeValue,
      };
      const response = await axios.patch(`${process.env.REACT_APP_API_URL}/unpaid/examfee`, data, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.status === 200) {
        navigate("/dashboard/feedetail");
      }
    } catch (error) {
      setError("Error updating exam fee. Please try again later.");
      console.error("Error updating exam fee:", error);
    }
  };


  const handleMiscFeeUpdate = async () => {
    try {
      const data = {
        standard: selectedStandard,
        miscFee: miscFeeValue,
        remarks: remarksValue,
      };
      const response = await axios.patch(`${process.env.REACT_APP_API_URL}/unpaid/miscfee`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        navigate("/dashboard/feedetail");
      }
    } catch (error) {
      setError("Error updating misc fee. Please try again later.");
      console.error("Error updating misc fee:", error);
    }
  };

  return (
    <>
      {error}
      <div className="form-control">
        <h2 className="text-center">Add Other Fee In Invoices</h2>
      </div>
      <hr />
      <div className="row">
        <div className="col-md-5">
          <select
            value={selectedStandard}
            onChange={handleStandardChange}
            className="form-control mx-2 shadow p-2 rounded"
          >
            <option value="">Select Standard</option>
            {standards.map((standard, index) => (
              <option key={index} value={standard}>
                {standard}
              </option>
            ))}
          </select>
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-md-3 text-center">
          <input
            type="number"
            className="form-control mx-2 shadow p-2 rounded-pill border-3 border-info"
            placeholder="Exam Fee here..."
            required
            autoComplete="off"
            value={examFeeValue}
            onChange={handleExamFeeChange}
          />
          <button className="btn btn-info" onClick={handleExamFeeUpdate}>
            Update Exam Fee
          </button>
        </div>
        <div className="col-md-3 text-center">
          <input
            type="number"
            className="form-control mx-2 shadow p-2 rounded-pill border-3 border-info"
            placeholder="Misc Fee here..."
            required
            autoComplete="off"
            value={miscFeeValue}
            onChange={handleMiscFeeChange}
          />
        </div>

          <div className="col-md-3 text-center">
         
          <input
            type="text"
            className="form-control mx-2 shadow p-2 rounded-pill border-3 border-info"
            placeholder="Remarks here..."
            required
            autoComplete="off"
            value={remarksValue}
            onChange={handleRemarksChange}
          />
          <button className="btn btn-info" onClick={handleMiscFeeUpdate}>
            Update Misc Fee
          </button>
        </div>
      </div>
    </>
  );
}
