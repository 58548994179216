// File: app.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Login from './components/Login';
import { InvoicesProvider } from './components/context/InvoiceContext'; 
import Dashboard from './components/Bars/Dashboard';
import Payment from './components/Payment/Payment';
import SearchedInvoice from './components/Payment/SearchedInvoice';
import About from './components/Pages/About';
import InvoiceFee from './components/Pages/InvoiceFee';
import AuthProvider from './components/context/AuthProvider';
import ProtectedRoute from './components/ProtectedRoute';  // Correct import
import Navbar from './components/Bars/Navbar';
import TasksView from './components/Assignments/TasksView';
import { Helmet } from 'react-helmet';

import AnnouncementPublished from './components/Pages/AnnouncementPublished';

const App = () => {
  return (
    <InvoicesProvider>
    <AuthProvider>
      <Router>
      <Helmet>
          <title>Islamic Scholar Public School</title>
          <meta name="description" content="Islamic Scholar Public School offers quality education with a focus on academic and moral development." />
          <meta name="keywords" content="Islamic School, Quality Education, Wah Cantt, Free Education for Orphans, Solar Electricity" />
        </Helmet>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/login" element={<Login />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/tasksview" element={<TasksView />} />
          <Route path="/invoicefee" element={<InvoiceFee />} />
          <Route path="/searchedinvoice/:admNo" element={<SearchedInvoice />} />
          <Route path="/announcementPublished" element={<AnnouncementPublished />} />
          
          <Route path="/dashboard/*" element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          } />
        </Routes>
      </Router>
    </AuthProvider>
    </InvoicesProvider>
  );
};

export default App;  // Default export
