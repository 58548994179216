import React, { useState } from 'react';
import axios from 'axios';
import { PDFDownloadLink } from '@react-pdf/renderer';
import FeePdf from '../Fee/Feepdf';

const InvoiceFee = () => {
  const [admno, setAdmno] = useState('');
  const [invoiceData, setInvoiceData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/invoicefee`, {
        params: { query: admno },
      });
      console.log('Fetched invoice data:', response.data);
      setInvoiceData(response.data.data);
    } catch (error) {
      console.error('Error fetching invoice data:', error.response ? error.response.data : error.message);
      setError('Error fetching invoice data');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='container'>
      <h2>Invoice Search</h2>
      {error && <div className="alert alert-danger">{error}</div>}
      <form onSubmit={handleSubmit} className="row mb-3 align-items-center">
        <div className="col-md-8">
          <input
            value={admno}
            onChange={(e) => setAdmno(e.target.value)}
            placeholder="Enter Admission Number"
            className="form-control mx-2 shadow p-2 rounded-pill border-3 border-info"
          />
        </div>
        <div className="col-md-4 text-center">
          <button
            type="submit"
            className="btn btn-primary shadow p-2 rounded"
            style={{ backgroundColor: "#0b4f6c", color: "white" }}
          >
            <b>Search</b>
          </button>
        </div>
      </form>
      {loading ? (
        <div>Loading...</div>
      ) : (
        invoiceData && (
          <div className='container'>
            <h3>Fee Detail of Adm No: {invoiceData.fee_adm_no}, {invoiceData.name}, Class: {invoiceData.FeeStandard}, For the Month of: {invoiceData.fmonth}/{invoiceData.fyear}</h3>
            <div>
              <p>Invoice No: {invoiceData.idf}</p>
              <p>Father Name: {invoiceData.father}</p>
              <p>Monthly Fee: {invoiceData.monthly_fee_feetbl}</p>
              <p>Arrears: {invoiceData.total_arrears}</p>
              <p>Total Fee: {invoiceData.total_fee}</p>
              {invoiceData.collection_by ? (
                <div>
                  <p>Fee Collection By: {invoiceData.collection_by}</p>
                  <p>Paid On: {invoiceData.payment_at}</p>
                </div>
              ) : (
                
                <PDFDownloadLink document={<FeePdf data={invoiceData} />} fileName={`Invoice_${invoiceData.fee_adm_no}.pdf`}>
                  {({ loading }) => (loading ? 'Loading document...' : 'Download Student Invoice in PDF')}
                </PDFDownloadLink>
              
              )}
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default InvoiceFee;