import React, { useEffect, useState, useContext, useMemo, useCallback } from "react";
import axios from "axios";
import userContext from "../context/UserContext";
import { useNavigate } from "react-router-dom";
import { PDFDownloadLink } from '@react-pdf/renderer';
import ConfirmationModal from './ConfirmationModal'; // Adjust the path accordingly

import BulkFeePdf from './Bulkpdf';


export default function FeeDetail() {
  axios.defaults.withCredentials = true;
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const { token } = useContext(userContext);

  // State variables
  const [standards, setStandards] = useState([]);
  const [standardsLoaded, setStandardsLoaded] = useState(false);
  const [selectedStandard, setSelectedStandard] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [error, setError] = useState(null);
  const [cachedInvoices, setCachedInvoices] = useState([]);
  const [filteredInvDetails, setFilteredInvDetails] = useState([]); // State for filtered invoices

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 50;

  const yearsArray = Array.from({ length: 21 }, (_, i) => 2020 + i);
  const monthsArray = Array.from({ length: 12 }, (_, i) => i + 1);

  useEffect(() => {
    const fetchStandards = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/classes`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setStandards(response.data);
        setStandardsLoaded(true);
      } catch (error) {
        setError(error);
        console.error("Error fetching standards:", error);
      }
    };

    fetchStandards();
  }, [token]);

  const fetchInvoices = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/fee`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setCachedInvoices(response.data.data); // Cache the fetched invoices
    } catch (error) {
      setError(error);
      console.error("Error fetching invoices:", error);
    }
  }, [token]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Fetch invoices after submitting the form
    await fetchInvoices();

    // Filter invoices based on selected criteria only after fetching invoices
    const filtered = cachedInvoices.filter((inv) =>
      (!selectedStandard || inv.FeeStandard === selectedStandard) &&
      (!selectedYear || inv.fyear === selectedYear) &&
      (!selectedMonth || inv.fmonth === selectedMonth)
    );

    setFilteredInvDetails(filtered); // Update filtered invoices
    // Reset pagination to the first page
    setCurrentPage(1);
  };

  const handleStandardChange = (e) => setSelectedStandard(e.target.value);
  const handleYearChange = (e) => setSelectedYear(e.target.value);
  const handleMonthChange = (e) => setSelectedMonth(e.target.value);

  const totalPages = Math.ceil(filteredInvDetails.length / recordsPerPage);
  const startIndex = (currentPage - 1) * recordsPerPage;
  const currentInvDetails = filteredInvDetails.slice(startIndex, startIndex + recordsPerPage);

  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);





 

  const memoizedStandards = useMemo(() => {
    if (!standardsLoaded) return [];
    return standards.map((standard) => (
      <option key={standard.sid} value={standard.standard}>
        Class: {standard.standard}
      </option>
    ));
  }, [standards, standardsLoaded]);

  const handleInsertFees = async () => {
    setShowModal(false); // Close the modal

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/fee/insert-fees`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      alert(`Inserted Records: ${response.data.insertedRecords}`);
      navigate("/dashboard/main");
    } catch (error) {
      console.error("Error inserting fees:", error);
      alert("Failed to insert fees");
    }
  };
  
  return (
    <div className="container">
      {error && <div className="alert alert-danger">{error.toString()}</div>}
      <div className="card-body">
        <div className="col-md-12 text-center">
          <h1 style={{ color: "#0b4f6c" }}><b>FEE RECORD</b></h1>
        </div>

        {/* Form for Selecting Criteria */}
        <form onSubmit={handleSubmit} className="row mb-3 align-items-center">
          <div className="col-md-3">
            {standardsLoaded ? (
              <select
                value={selectedStandard}
                onChange={handleStandardChange}
                className="form-control mx-2 shadow p-2 rounded-pill border-3 border-info"
              >
                <option value=""><b>Select Standard</b></option>
                {memoizedStandards}
              </select>
            ) : (
              <div>Loading standards...</div>
            )}
          </div>

          <div className="col-md-2">
            <select
              value={selectedMonth}
              onChange={handleMonthChange}
              className="form-control mx-2 shadow p-2 rounded-pill border-3 border-info"
            >
              <option value="">Select Month</option>
              {monthsArray.map((month) => (
                <option key={month} value={month}>
                  Month No: {month}
                </option>
              ))}
            </select>
          </div>

          <div className="col-md-2">
            <select
              value={selectedYear}
              onChange={handleYearChange}
              className="form-control mx-2 shadow p-2 rounded-pill border-3 border-info"
            >
              <option value="">Select Year</option>
              {yearsArray.map((year) => (
                <option key={year} value={year}>
                  Year: {year}
                </option>
              ))}
            </select>
          </div>

          <div className="col-md-2 text-center mt-3">
            <button type="submit" className="btn btn-primary btn-sm mb-1 shadow p-2 rounded" style={{ backgroundColor: "#61197d", color: "white" }}>
              <b>Go</b>
            </button>
          </div>
        </form>

        {/* Export and Action Buttons */}
        <div>
        <button 
        className="btn btn-success ml-1 btn-sm mb-1 shadow p-2 rounded" 
        onClick={() => setShowModal(true)}>Generate All Invoices</button>

      <ConfirmationModal
        show={showModal}
        handleClose={() => setShowModal(false)}
        handleConfirm={handleInsertFees}
      />
          <button className="btn btn-success ml-1 btn-sm mb-1 shadow p-2 rounded" 
            style={{ backgroundColor: "#bbc238", color: "Black" }} 
            onClick={() => navigate("/dashboard/feepaid")}>
            <b>Fee Paid</b>
          </button>
          <button className="btn btn-danger ml-1 btn-sm mb-1 shadow p-2 rounded" 
            style={{ backgroundColor: "#dd1c1a", color: "white" }} 
            onClick={() => navigate("/dashboard/unpaidfee")}>
            <b>Unpaid Fee</b>
          </button>
          <button className="btn ml-1 btn-sm mb-1 shadow p-2 rounded" 
            style={{ backgroundColor: "#0b4f6c", color: "white" }} 
            onClick={() => navigate(`/dashboard/feemisc`)}>
            <b>Add More</b>
          </button>

          <PDFDownloadLink
            fileName="BulkInvoice.pdf"
            className="btn ml-1 btn-sm mb-1 shadow p-2 rounded"
            document={<BulkFeePdf invoices={filteredInvDetails} />} 
            style={{ backgroundColor: "#0b4f6c", color: 'white', padding: '10px', marginBottom: '1rem' }}
          >
            {({ loading }) => (loading ? 'Generating PDF...' : 'Download All Invoices')}
          </PDFDownloadLink>
        </div>

        <table className="table table-bordered">
          <thead>
            <tr>
              <th>No</th>
              <th>Name</th>
              <th>Class</th>
              <th>Sec</th>
              <th>Fee</th>
              <th>Fine</th>
              <th>Arrears</th>
              <th>Bal</th>
              <th>Total Fee</th>
              <th>Collection</th>
              <th>M/Y</th>
            
            </tr>
          </thead>
          <tbody>
            {(!selectedStandard || !selectedYear || !selectedMonth) ? (
              <tr>
                <td colSpan="11" className="text-center">Please select Standard, Year, and Month to view invoices.</td>
              </tr>
            ) : currentInvDetails.length === 0 ? (
              <tr>
                <td colSpan="11" className="text-center">No invoices found for the selected criteria.</td>
              </tr>
            ) : (
              currentInvDetails.map((inv) => (
                <tr key={inv.idf}>
                  <td>{inv.fee_adm_no}</td>
                  <td>{inv.name}</td>
                  <td>{inv.FeeStandard}</td>
                  <td>{inv.sec}</td>
                  <td>{inv.monthly_fee}</td>
                  <td>{inv.fine_fee}</td>
                  <td>{inv.total_arrears}</td>
                  <td>{inv.total_balance}</td>
                  <td>{inv.total_fee}</td>
                  <td>{inv.total_collection}</td>
                  <td>{inv.fmonth} / {inv.fyear}</td>
                  
                </tr>
              ))
            )}
          </tbody>
        </table>

        {/* Pagination Controls */}
        {filteredInvDetails.length > 0 && (
          <div className="pagination">
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index + 1}
                className={`btn ${currentPage === index + 1 ? "btn-success" : "btn-primary"}`}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
