import React, { useState, useContext } from 'react';
import axios from 'axios';
import userContext from "../context/UserContext"; // Make sure this context exists
import "bootstrap/dist/css/bootstrap.min.css";
import "../CSS/Login.css"; // Use the same CSS for consistent styling

export default function User() {
    const { token } = useContext(userContext);
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    
    const handleChangePassword = async (e) => {
        e.preventDefault();
        
        // Check if newPassword and confirmPassword match
        if (newPassword !== confirmPassword) {
            setMessage("New password and confirm password do not match.");
            return;
        }
        
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/user/change-password`, {
                currentPassword,
                newPassword
            }, {
                headers: {
                    Authorization: `Bearer ${token}` // Use the token from context
                }
            });

            setMessage(response.data.message);
            // Optionally reset fields after success
            setCurrentPassword('');
            setNewPassword('');
            setConfirmPassword('');
        } catch (error) {
            console.error('Error changing password:', error);
            setMessage(error.response ? error.response.data.message : 'Error changing password');
        }
    };

    return (
        <div className="login-background">
            <div className="container col-md-4 login-box">
                <h1 className="my-4 text-center">Change Password</h1>
                {message && <div className="alert alert-info">{message}</div>}
                <form onSubmit={handleChangePassword}>
                    <div className="form-group">
                        <label>Current Password</label>
                        <input
                            type="password"
                            className="form-control"
                            placeholder="Current Password"
                            value={currentPassword}
                            onChange={(e) => setCurrentPassword(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>New Password</label>
                        <input
                            type="password"
                            className="form-control"
                            placeholder="New Password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Confirm New Password</label>
                        <input
                            type="password"
                            className="form-control"
                            placeholder="Confirm New Password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                        />
                    </div>
                    <button type="submit" className="btn btn-primary mt-2">
                        Change Password
                    </button>
                </form>
                <div className="contact-info">
                    <p>Contact us: <a href="mailto:techinfolab360@gmail.com" style={{color: 'lightblue'}}>techinfolab360@gmail.com</a></p>
                    <p>Website: <a href="https://techinfolab360.xyz" style={{color: 'lightblue'}}>https://techinfolab360.xyz</a></p>
                </div>
            </div>
        </div>
    );
}
