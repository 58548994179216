import React, { useState, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import userContext from '../context/UserContext';

const CreateAnnouncement = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [image, setImage] = useState(null);
  const navigate = useNavigate();
  const { token, user } = useContext(userContext); // Assuming username is stored here
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('image', image);
    formData.append('username', user.username); // Add the username to formData
    
    
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/info/add`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`
        }


      });
      navigate('/dashboard/announcementslist');
    } catch (err) {
      console.error("Error creating announcement:", err);
    }
  };

  return (
    <div className="card col-md-8 mx-auto">
      <div className="card-header">
        <h2 className="text-center">New Announcement</h2>
      </div>
      <div className="card-body">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label><b>Title</b></label>
            <input 
              type="text"
              className="form-control"
              placeholder="Announcement Title..."
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label><b>Description</b></label>
            <textarea
              className="form-control"
              placeholder="Announcement Description..."
              onChange={(e) => setDescription(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label><b>Image</b></label>
            <input type="file" className="form-control" name="image" onChange={(e) => setImage(e.target.files[0])} />
          </div>
          <button className="btn btn-primary mt-3">Create Announcement</button>
        </form>
      </div>
    </div>
  );
};

export default CreateAnnouncement;
