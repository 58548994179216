import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import userContext from "../context/UserContext";
import { useNavigate } from "react-router-dom";

export default function TaskCreate() {
  const { user, token } = useContext(userContext);
  const navigate = useNavigate();
  const [standards, setStandards] = useState([]);
  const [selectedStandard, setSelectedStandard] = useState("");
  const [subject, setSubject] = useState("");
  const [task, setTask] = useState("");
  const [createdAt, setCreatedAt] = useState("");
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    const fetchStandards = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/classes`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setStandards(response.data); // Assuming response.data contains an array of distinct standards
      } catch (error) {
        console.error("Error fetching task standards:", error.response ? error.response.data : error.message);
        setError('Error fetching task standards');
      }
    };

    fetchStandards();
  }, [token]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccessMessage("");

    if (!selectedStandard || !subject || !task || !createdAt) {
      setError("All fields are required.");
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/tasks/new-task`, {
        task_standard: selectedStandard,
        subject,
        task,
        created_task_at: createdAt,
        user: user.username // Make sure to specify how you want to fetch user data
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }); 

      if (response.data.success) {
        setSuccessMessage("Task created successfully!");
        // Reset fields
        setSelectedStandard("");
        setSubject("");
        setTask("");
        setCreatedAt("");
        navigate('/dashboard/assigntasks');
      } else {
        setError('Failed to create task');
      }
    } catch (error) {
      console.error("Error creating task:", error.response ? error.response.data : error.message);
      setError('Error creating task');
    }
  };
  return (
    <div className="container">
      <h1 className='text-center'>Create New Task</h1>
      {error && <div className="alert alert-danger">{error}</div>}
      {successMessage && <div className="alert alert-success">{successMessage}</div>}

      <form onSubmit={handleSubmit}>
        <div className="form-group mb-3">
          <label><b>Standard</b></label>
          <select 
            value={selectedStandard} 
            onChange={(e) => setSelectedStandard(e.target.value)} 
            className="form-control"
          >
            <option value="">Select Task Standard</option>
            {standards.map((standard) => (
              <option key={standard.standard} value={standard.standard}>
                {standard.standard}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group mb-3">
          <label><b>Subject</b></label>
          <input 
            type="text" 
            value={subject} 
            onChange={(e) => setSubject(e.target.value)} 
            className="form-control" 
            placeholder="Enter subject" 
          />
        </div>

        <div className="form-group mb-3">
          <label><b>Task</b></label>
          <input 
            type="text" 
            value={task} 
            onChange={(e) => setTask(e.target.value)} 
            className="form-control" 
            placeholder="Enter task" 
          />
        </div>

        <div className="form-group mb-3">
          <label><b>Created At</b></label>
          <input 
            type="date" 
            value={createdAt} 
            onChange={(e) => setCreatedAt(e.target.value)} 
            className="form-control" 
          />
        </div>

        <button type="submit" className="btn btn-primary">Create Task</button>
      </form>
    </div>
  );
}
