import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import userContext from '../context/UserContext';

const AddQtoPaper = () => {
  const [standard, setStandard] = useState('');
  const [subject, setSubject] = useState('');
  const [exam, setExam] = useState('');
  const [examYear, setExamYear] = useState('');
  const [selectedQuestions, setSelectedQuestions] = useState(new Set());
  const [questions, setQuestions] = useState([]);
  const { token } = useContext(userContext);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [standards, setStandards] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [marksInput, setMarksInput] = useState({});
  const [years, setYears] = useState([]);
  const [exams, setExams] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const standardsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/classes`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setStandards(standardsResponse.data);

        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/resultprep/selectboxes`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setYears(data.years);
        setExams(data.months);
      } catch (error) {
        console.error('Error fetching data:', error);
        setErrorMessage('Failed to fetch data');
      }
    };

    fetchData();
  }, [token]);

  useEffect(() => {
    const fetchSubjects = async () => {
      if (standard) {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/paperserver/subjects`, {
            params: { standard },
            headers: { Authorization: `Bearer ${token}` }
          });
          setSubjects(response.data);
        } catch (error) {
          console.error('Error fetching subjects:', error);
          setErrorMessage('Failed to fetch subjects');
        }
      } else {
        setSubjects([]);
        setSubject('');
      }
    };

    fetchSubjects();
  }, [standard, token]);

  useEffect(() => {
    const fetchQuestions = async () => {
      if (standard && subject) {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/paperserver/questions`, {
            params: { standard, subject },
            headers: { Authorization: `Bearer ${token}` },
          });
          setQuestions(response.data);
        } catch (error) {
          console.error('Error fetching questions:', error);
          setErrorMessage('Failed to fetch questions');
        }
      }
    };

    fetchQuestions();
  }, [standard, subject, token]);

  const handleCheckboxChange = (qid) => {
    setSelectedQuestions(prevSelected => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(qid)) {
        newSelected.delete(qid);
        const updatedMarksInput = { ...marksInput };
        delete updatedMarksInput[qid];
        setMarksInput(updatedMarksInput);
      } else {
        newSelected.add(qid);
      }
      return newSelected;
    });
  };

  const handleMarkChange = (qid, value) => {
    setMarksInput(prev => ({ ...prev, [qid]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const requests = Array.from(selectedQuestions).map(qid => {
        const marks = marksInput[qid] ? marksInput[qid] : 0;
        return axios.post(`${process.env.REACT_APP_API_URL}/paperserver/addqtopaper`, {
          standard,
          q_id: qid,
          marks,
          exam,
          examYear,
        }, {
          headers: { Authorization: `Bearer ${token}` },
        });
      });

      await Promise.all(requests);
      setSuccessMessage('Questions added to paper successfully!');
      setTimeout(() => navigate('/dashboard/papers'), 2000);
    } catch (error) {
      console.error('Error adding questions to paper:', error);
      setErrorMessage('Error adding questions to paper');
    }
  };

  const filteredQuestions = questions.filter(question =>
    question.chapter.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className='container'>
      <h3 className="text-center mb-3">Select & Add Questions to the Required Paper</h3>

      <form onSubmit={handleSubmit}>
        <div className="row mb-3">
          <div className="col-md-3">
            <label htmlFor="standard">Standard:</label>
            <select
              id="standard"
              className="form-control"
              value={standard}
              onChange={(e) => setStandard(e.target.value)}
            >
              <option value="">Select Standard</option>
              {standards.map(std => (
                <option key={std.sid} value={std.sid}>{std.standard}</option>
              ))}
            </select>
          </div>

          <div className="col-md-3">
            <label htmlFor="subject">Subject:</label>
            <select
              id="subject"
              className="form-control"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              disabled={!subjects.length}
            >
              <option value="">Select Subject</option>
              {subjects.map(sub => (
                <option key={sub.sid} value={sub.sid}>{sub.subject}</option>
              ))}
            </select>
          </div>

          <div className="col-md-3">
            <label htmlFor="exam">Exam:</label>
            <select id="exam" className="form-control" value={exam} onChange={(e) => setExam(e.target.value)}>
              <option value="">Select Exam</option>
              {exams.map(examName => (
                <option key={examName.month} value={examName.month}>{examName.month}</option>
              ))}
            </select>
          </div>

          <div className="col-md-3">
            <label htmlFor="examYear">Exam Year:</label>
            <select id="examYear" className="form-control" value={examYear} onChange={(e) => setExamYear(e.target.value)}>
              <option value="">Select Exam Year</option>
              {years.map(year => (
                <option key={year.year} value={year.year}>{year.year}</option>
              ))}
            </select>
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-12">
            <input
              type="text"
              className="form-control"
              placeholder="Search by Chapter..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>

        <table className="table">
          <thead>
            <tr>
              <th>Select</th>
              <th>Q ID</th>
              <th>Unit</th>
              <th>Type</th>
              <th>Question</th>
              <th>Marks</th>
            </tr>
          </thead>
          <tbody>
            {filteredQuestions.map(question => (
              <tr key={question.qid}>
                <td>
                  <input
                    type="checkbox"
                    onChange={() => handleCheckboxChange(question.qid)}
                    checked={selectedQuestions.has(question.qid)}
                  />
                </td>
                <td>{question.qid}</td>
                <td>{question.chapter}</td>
                <td>{question.q_type}</td>
                <td>{question.question}</td>
                <td>
                  <input
                    type="number"
                    min="0"
                    className='mx-2 shadow p-2 rounded-pill border-3 border-info'
                    value={marksInput[question.qid] || ''}
                    onChange={(e) => handleMarkChange(question.qid, e.target.value)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="row mb-3">
          <div className="col-12">
            <button type="submit" className="btn btn-success">Add Q to Paper</button>
            <button type="button" className="btn btn-secondary ml-1" onClick={() => navigate('/dashboard/papers')}>Back</button>
          </div>
        </div>
      </form>

      {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
      {successMessage && <div className="alert alert-success">{successMessage}</div>}
    </div>
  );
};

export default AddQtoPaper;
