import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";
import userContext from '../context/UserContext';
import { FaUserGraduate, FaWallet, FaClipboardList, FaUserTie, FaTachometerAlt, FaMoneyBill } from "react-icons/fa";

import '../CSS/styledashboard.css';

const Main = () => {
  const { token } = useContext(userContext);
  const [studentCount, setStudentCount] = useState(0);
  const [totalFee, setTotalFee] = useState(0);
  const [collection, setCollection] = useState(0);
  const [totalBalance, setTotalBalance] = useState(0);
  const [salary, setSalary] = useState(0);
  const [totalexpense, setTotalExpense] = useState(0);

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        // Fetching student count
        const studentResponse = await axios.get(`${process.env.REACT_APP_API_URL}/feestats/students/count`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setStudentCount(studentResponse.data.count);
  
        // Fetching fee data
        const feeResponse = await axios.get(`${process.env.REACT_APP_API_URL}/feestats/mainhead`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (feeResponse.data && feeResponse.data.data) {
          const { total_fee, total_collection, total_balance } = feeResponse.data.data;
          setTotalFee(total_fee || 0);
          setCollection(total_collection || 0);
          setTotalBalance(total_balance || 0);
        } else {
          console.log("No fees found.");
          setTotalFee(0);
          setCollection(0);
          setTotalBalance(0);
        }
  
        // Fetching expenses
        const expenseResponse = await axios.get(`${process.env.REACT_APP_API_URL}/feestats/expensehead`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (expenseResponse.data && expenseResponse.data.data) {
          const { total_expense } = expenseResponse.data.data;
          setTotalExpense(total_expense || 0);
        } else {
          console.log("No expenses found.");
          setTotalExpense(0);
        }
  
        // Fetching salaries
        const salaryResponse = await axios.get(`${process.env.REACT_APP_API_URL}/salary`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (salaryResponse.data && salaryResponse.data.data) {
          const totalNetSalary = salaryResponse.data.data.total_net_salary || 0;
          setSalary(totalNetSalary);
        } else {
          console.log("No salary data found.");
          setSalary(0);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    fetchAllData();
  }, [token]);
  
  

  return (
    <div className="dashboard-container">
      <h2 className="dashboard-header">DASHBOARD - <Link to="/dashboard/report">REPORT</Link></h2>
      <div className="row">
        <div className="col-md-3 mb-4">
          <div className="card text-center">
            <div className="card-body d-flex flex-column align-items-center">
              <FaUserGraduate className="dashboard-icon icon-color" style={{ fontSize: '3rem' }} />
              <h3 className="card-title">Students</h3>
              <h3 className="card-text text-success">{studentCount}</h3>
            </div>
          </div>
        </div>
        <div className="col-md-3 mb-4">
          <div className="card text-center">
            <div className="card-body d-flex flex-column align-items-center">
              <FaWallet className="dashboard-icon icon-color" style={{ fontSize: '3rem' }} />
              <h3 className="card-title">Total Fee</h3>
              <h3 className="card-text text-success">Rs. {totalFee}/-</h3>
            </div>
          </div>
        </div>
        <div className="col-md-3 mb-4">
          <div className="card text-center">
            <div className="card-body d-flex flex-column align-items-center">
              <FaClipboardList className="dashboard-icon icon-color" style={{ fontSize: '3rem' }} />
              <h3 className="card-title">Total Collection</h3>
              <h3 className="card-text text-success">Rs. {collection}/-</h3>
            </div>
          </div>
        </div>
        <div className="col-md-3 mb-4">
          <div className="card text-center">
            <div className="card-body d-flex flex-column align-items-center">
              <FaTachometerAlt className="dashboard-icon icon-color" style={{ fontSize: '3rem' }} />
              <h3 className="card-title">Total Balance</h3>
              <h3 className="card-text text-warning">Rs. {totalBalance}/-</h3> 
            </div>
          </div>
        </div>
        <div className="col-md-3 mb-4">
          <div className="card text-center">
            <div className="card-body d-flex flex-column align-items-center">
              <FaUserTie className="dashboard-icon icon-color" style={{ fontSize: '3rem' }} />
              <h3 className="card-title">Salaries Paid</h3>
              <h3 className="card-text text-danger">Rs. {salary}/-</h3>
            </div>
          </div>
        </div>
        <div className="col-md-3 mb-4">
          <div className="card text-center">
            <div className="card-body d-flex flex-column align-items-center">
              <FaMoneyBill className="dashboard-icon icon-color" style={{ fontSize: '3rem' }} />
              <h3 className="card-title">Expenses</h3>
              <h3 className="card-text text-danger">Rs. {totalexpense}/-</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;